.podcast-details {
  .hero-section {
    padding: 64px 0;
    position: relative;
    .content {
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      @media only screen and (max-width: $breakpoint_880) {
        flex-direction: column;
      }

      &__left {
        font-style: normal;

        @media only screen and (max-width: $breakpoint_880) {
          width: 100%;
          align-items: center;
          display: flex;
          flex-direction: column;
        }

        .name {
          font-family: "Playfair Display", serif;
          font-weight: 600;
          font-size: 42px;
          line-height: 117%;
          display: flex;
          align-items: center;
          padding-bottom: 16px;
        }

        .description {
          font-family: "Roboto", sans-serif;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          color: $grey_light;
        }

        .buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-top: 32px;
          .button-play {
            display: flex;
            min-width: 160px;
            height: 40px;
            justify-content: center;
            align-items: center;
            margin: 0 12px 0 0;
            border: none;
            border-radius: 100px;
            color: white;
            font-weight: 700;
            cursor: pointer;

            &.red {
              background: $red;

              &:hover {
                background: linear-gradient(0deg, rgba(20, 17, 64, 0.2), rgba(20, 17, 64, 0.2)), #dd4e52;
              }
            }
            &.blue {
              background: $blue;
            }
          }
          img {
            margin-right: 10px;
          }
        }
      }

      &__right {
        z-index: 2;
        align-self: center;
        position: relative;
        @media only screen and (max-width: $breakpoint_880) {
          display: flex;
          margin-top: 50px;
          align-self: left;
        }

        .gradient {
          position: absolute;
          top: 50%;
          margin-top: -120px;
          right: 0;
          width: 54.5%;
          height: 240px;
          z-index: -1;
          background: linear-gradient(101.44deg, #141140 38.41%, #dd4e52 100%);

          &.jazz {
            background: linear-gradient(110.01deg, #141140 38.41%, #4866e0 91.66%);
          }

          @media only screen and (max-width: $breakpoint_880) {
            top: unset;
            position: absolute;
            bottom: 100px;
            width: 100%;
            height: 120px;
          }
        }

        img {
          width: 352px;
          height: 352px;
          object-fit: cover;
          float: right;
          @media only screen and (max-width: $breakpoint_880) {
            float: unset;
            margin: auto;
            width: 200px;
            height: 200px;
          }
        }
      }
    }
  }

  .chapters-list {
    padding-bottom: 30px;

    .chapter-container {
      display: flex;
      flex-direction: row;
      padding-bottom: 12px;
      padding-top: 12px;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background: rgba(221, 78, 82, 0.1);

        &.__blue {
          background: $blue_light;
        }

        .index {
          span {
            display: none;
          }
          .icon-play-circle {
            display: block;
          }
        }
      }

      .index {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #8f99aa;
        min-width: 40px;
        margin: 0px 15px;

        span {
          margin: 0px auto;
        }

        @media only screen and (max-width: $breakpoint_880) {
          margin: 0px 5px;
        }
      }

      .icon-play-circle {
        width: 40px;
        height: 40px;
        display: none;
        cursor: pointer;
      }

      .icon-play-playing {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }

      .details {
        width: 85%;
        font-family: "Roboto", sans-serif;
        font-size: 16px;

        .release-date {
          font-size: 14px;
          line-height: 135%;
          color: #3d3c4b;
        }

        .name {
          font-weight: bold;
          line-height: 135%;
          padding: 8px 0px 4px 0px;
        }

        .description {
          line-height: 125%;
          color: $grey_light;
        }

        .duration {
          line-height: 125%;
          color: $grey_light;
          padding: 8px 0px 8px 0px;
          display: flex;
          flex-direction: row;

          img {
            width: 15px;
            height: 15px;
            margin: auto 5px auto 0px;
          }
        }
      }

      .openChapter {
        display: flex;
        align-items: center;

        img {
          margin: 0px 13px;

          @media only screen and (max-width: $breakpoint_880) {
            margin: 0px 0px 0px 5px;
          }
        }
      }
    }

    .load-more-container {
      display: flex;

      button {
        width: 160px;
        height: 40px;
        margin: 10px auto 48px;
        background: white;
        border: 1px;
        border-style: solid;
        border-radius: 25px;
        outline: inherit;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        cursor: pointer;

        &.red {
          color: $red;
          border-color: $red;
        }

        &.blue {
          color: $blue;
          border-color: $blue;
        }
      }
    }
  }

  .loading {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.also-like-podcasts {
  padding-bottom: 80px;

  .header {
    padding: 56px 0px 40px 0px;
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    display: flex;
    align-items: center;
  }

  .also-like-podcasts-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;

    a {
      text-decoration: none;
      color: inherit;
      margin: 0px 5px;

      @media only screen and (max-width: $breakpoint_880) {
        align-self: flex-start;
        padding-bottom: 50px;
      }

      img {
        width: 224px;
        height: 224px;
        object-fit: cover;

        @media only screen and (max-width: $breakpoint_880) {
          width: 100%;
          height: 100%;
          max-width: 400px;
        }
      }

      .name {
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        padding: 14px 0px 6px 0px;
        max-width: 224px;
      }
    }
  }
}

.voucher-activation {
  &__hero {
    font-family: "Playfair Display", serif;
    color: white;
    height: 235px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #430000;
    background-image: url(../assets/images/Christmas/Music.svg);
    background-position: center;
    background-repeat: no-repeat;

    @media only screen and (max-width: $breakpoint_1024) {
      height: 215px;
    }

    h1 {
      font-size: 55px;
      font-weight: 400;
      line-height: 77px;
      z-index: 1;
      background: radial-gradient(60.73% 60.73% at 52.54% 39.27%, #ebae71 0%, #ac7946 100%);

      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      cursor: default;
      align-self: flex-start;
      text-align: start;
      margin: 0px auto;

      @media only screen and (max-width: $breakpoint_1024) {
        font-size: 40px;
        line-height: 52px;
      }

      @media only screen and (max-width: $breakpoint_518) {
        font-size: 27px;
        text-align: center;
      }
    }
  }
  &__content {
    display: flex;
    padding: 0px 25px;
    margin: 70px auto 70px auto;
    justify-content: center;

    @media only screen and (max-width: $breakpoint_1024) {
      flex-direction: column;
      margin: 20px auto;
    }

    .voucher-box {
      width: 568px;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(5, 10, 34, 0.07);
      margin-right: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      order: 1;

      @media only screen and (max-width: $breakpoint_1024) {
        max-width: 600px;
        width: 100%;
        margin: 20px auto;
        order: 2;
      }

      &__image-container {
        height: 291px;
        position: relative;

        > img {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 300px;
          object-fit: cover;
          object-fit: cover;
          @media only screen and (max-width: $breakpoint_1024) {
            max-width: 100%;
          }
        }

        &__text1,
        &__text2 {
          position: absolute;
          left: 40px;
          padding-right: 40px;
          font-weight: 400;
          z-index: 2;
          background: radial-gradient(60.73% 60.73% at 52.54% 39.27%, #ebae71 0%, #ac7946 100%);
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
          cursor: default;
          user-select: none;

          &.dark-text {
            background: radial-gradient(60.73% 60.73% at 52.54% 39.27%, #141140 0%, #141140 100%);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
          }
        }

        &__text1 {
          top: 50px;
          font-family: "Playfair Display", serif;
          font-size: 40px;
          line-height: 53px;
          text-shadow: 5px 4px 2px rgba(67, 0, 0, 0.3);

          @media only screen and (max-width: $breakpoint_518) {
            font-size: 35px;
          }
        }

        &__text2 {
          top: 120px;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          line-height: 28px;

          @media only screen and (max-width: $breakpoint_1200) {
            top: 170px;
          }
        }
      }

      &__text-container {
        margin-right: auto;
        padding: 33px 38px;
        margin: auto 0px;
        font-family: "Roboto", sans-serif;

        > img {
          position: absolute;
          width: 86px;
          height: 87px;
          right: 50px;
          top: 40px;

          @media only screen and (max-width: $breakpoint_518) {
            right: 22px;
            top: -25px;
          }
        }

        &__title {
          font-size: 20px;
          font-weight: 700;
          padding-bottom: 30px;
        }

        &__description {
          font-size: 16px;
          font-weight: 400;
          line-height: 27px;
        }
      }
    }

    .voucher-form {
      font-family: "Roboto", sans-serif;
      width: 600px;
      margin-left: 50px;
      order: 2;
      font-size: 20px;
      font-weight: 400;

      @media only screen and (max-width: $breakpoint_1024) {
        margin: 0px auto;
        order: 1;
        width: unset;
        max-width: 600px;
        width: 100%;
      }

      &__title-1,
      &__title-2 {
        font-family: "Playfair Display", serif;
        font-size: 40px;
        padding-bottom: 21px;
        line-height: 38px;
      }

      &__title-2 {
        padding-top: 40px;
      }

      > p {
        padding-bottom: 21px;
      }

      &__wrapper {
        display: flex;
        flex-direction: row;

        @media only screen and (max-width: $breakpoint_1200) {
          flex-direction: column;
        }

        > p {
          font-size: 16px;
          font-weight: 400;
          line-height: 27px;
          color: #dd4e52;
          padding-left: 50px;
          align-self: flex-end;

          @media only screen and (max-width: $breakpoint_1200) {
            padding-left: 0px;
            padding-top: 40px;
            align-self: flex-start;
          }
        }

        .voucher-activation-form {
          min-width: 329px;
          background: #f4f4f4;
          border-radius: 11px;
          padding: 20px;

          @media only screen and (max-width: $breakpoint_518) {
            min-width: 286px;
          }

          h1 {
            font-weight: 700;
            padding-bottom: 17px;
          }
        }
      }
    }
  }

  &.partner {
    .voucher-activation__content {
      flex-direction: column;
      margin: 20px 0;
      .hero {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #eb008a;
        font-size: 20px;
      }
      .logo {
        max-width: 300px;
        width: 250px;
        img {
          width: 100%;
        }
      }

      .voucher-form {
        margin-top: 20px;
        width: unset;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        .voucher-form__wrapper {
          display: block;
          transition: all 200ms;
          & > p {
            font-size: 13px;
            padding-top: 3px;
          }
        }
        .voucher-form__title-1 {
          font-size: 20px;
          font-family: $roboto;
        }
      }
    }
  }
}

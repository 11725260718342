@import "./constants.scss";

.mainpage {
  display: flex;
  flex-direction: column;
  // height: 100%;
  // background: $grey_mlight;
}
.bar-mainpage {
  background: linear-gradient(102.45deg, #141140 38.41%, #dd4e52 100%);
  &__content {
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 0;
  }
  &__left {
    width: 45%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 151px;
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    color: $white;
    flex: 1;
  }
  &__right {
    width: 55%;
    background: rgba(255, 255, 255, 0.4);
    padding: 24px;
    .composer-quote {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      padding-right: 60px;
    }
    .error {
      font-size: 16px;
    }
    .composer-image {
      width: 64px;
      height: 64px;
      min-width: 64px;
      min-height: 64px;
      border-radius: 50%;
      border: 2px solid $red;
      margin-right: 24px;
    }
    .quote-text {
      line-height: 24px;
      font-size: 16px;
      color: $black;
      max-width: 500px;
      a,
      a:visited {
        color: $black;
        font-weight: bold;
        text-decoration: underline;
      }
    }
    .quote-down {
      position: absolute;
      top: 50%;
      right: 0px;
      margin-top: -12px;
    }
    .composer-name {
      font-weight: bold;
      line-height: 23px;
      font-size: 16px;
      margin-top: 8px;
      a {
        color: $black;
      }
    }
  }
}
.discover {
  padding: 40px 0;
  &__head {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    margin-bottom: 48px;
  }
  &__boxes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-box {
      width: 23%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: $white;
      border-radius: 8px;
      padding: 24px;
      font-weight: bold;
      line-height: 28px;
      font-size: 18px;
      text-align: right;
      cursor: pointer;
      position: relative;
      color: $black;
      @extend .bordered;
      &:hover {
        box-shadow: 0px 4px 8px rgba(143, 153, 170, 0.5);
        text-decoration: none;
      }
      .title {
        max-width: 110px;
        text-align: left;
        > span {
          display: block;
        }
      }
      img {
        margin-top: 5px;
        width: 56px;
        height: 56px;
      }
      .border-bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 8px;
        border-radius: 0 0 8px 8px;
      }
      &.brown {
        .border-bottom {
          background: $brown;
        }
        span {
          color: $brown;
        }
      }
      &.green {
        .border-bottom {
          background: $green;
        }
        span {
          color: $green;
        }
      }
      &.purple {
        .border-bottom {
          background: $purple;
        }
        span {
          color: $purple;
        }
      }
      &.red {
        .border-bottom {
          background: $red;
        }
        span {
          color: $red;
        }
      }
      &.blue {
        .border-bottom {
          background: $blue;
        }
        span {
          color: $blue;
        }
      }
    }
  }
}

.top-picks {
  padding: 80px 0;
  max-width: 1120px;
  margin: 0 auto;
  &__head {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    margin-bottom: 48px;
  }
  &__cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .card {
      width: 30%;
    }
  }
}
.mainpage.jazz {
  .bar-mainpage {
    background: linear-gradient(110.01deg, #141140 38.41%, #4866e0 91.66%);
    &__right {
      .composer-quote {
        .composer-image {
          border-color: $blue;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_1200) {
  .bar-mainpage {
    &__content {
      max-width: 100%;
      padding: 40px 30px;
    }
    &__left {
      width: 38%;
    }
    &__right {
      width: 62%;
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .discover {
    padding: 40px 0;
    &__boxes {
      flex-wrap: wrap;
      &-box {
        width: 48%;
        margin-bottom: 16px;
        text-align: right;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .bar-mainpage {
    &__left {
      width: 35%;
    }
    &__right {
      width: 66%;
    }
    .quote-text {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .bar-mainpage {
    &__content {
      padding: 32px 16px;
      flex-direction: column;
      height: auto;
    }
    &__left {
      width: 100%;
      margin-bottom: 0px;
      word-break: break-word;
    }
    &__right {
      display: none;
    }
    .quote-text {
      font-size: 16px;
    }
    .composer-name {
      font-size: 14px;
    }
  }
  .discover {
    padding: 40px 0 70px 0;
    &__boxes {
      &-box {
        padding: 20px;
        overflow: hidden;
      }
    }
  }
}

.page-section-filters-container {
  font-family: $roboto;
  font-size: 16px;
  font-weight: normal;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .filter {
    padding: 8px;
    transition: all 200ms;
    border-radius: 20px;
    border: 1px solid $red;
    margin: 5px 5px;
    font-size: 16px;
    @extend .hover-shadow;
    color: $red;
    &.active{
      background-color: $red;
      border: 1px solid $red;
      color: $white;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
    }
    &:hover{
      color: $white;
      background-color: $red;
      border: 1px solid $red;
    }
  }

  &.jazz {
    .filter {      
      border: 1px solid $blue;
      color: $blue;
      &.active{
        background-color: $blue;
        color: $white;
        border: 1px solid $blue;
      }
      &:hover{
        color: $white;
        background-color: $blue;
        border: 1px solid $blue;
      }
    }
  }
}
.congratulations {
  .loader {
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .welcome__logo {
    margin-bottom: 50px;
  }
  .congratulations-header {
    line-height: 38px;
    font-family: 'Playfair Display', serif;
    font-size: 28px;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 30px;
  }
  .box-composer {
    position: relative;
    max-width: 340px;
    .quote-up {
      position: absolute;
      top: 16px;
      left: 16px;
      margin: 0;
    }
    .quote-down {
      position: absolute;
      bottom: 16px;
      right: 16px;
      margin: 0;
    }
    .composer-image {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      border: 2px solid $red;
      margin-bottom: 20px;
      &.jazz {
        border-color: $blue;
      }
    }
  }
  .composer-name {
    a {
      font-size: 14px;
      line-height: 20px;
      color: $black;
      font-weight: bold;
    }
  }
  .quote-text {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 16px;
  }
  .button-red {
    margin-top: 48px;
  }
}

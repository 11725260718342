.payment {
  padding: 40px 0;
  .subscription-info-box {
    margin-right: 96px;
    width: 320px;
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .box {
    background: $white;
    max-width: 320px;
    position: relative;
    padding-top: 32px;
    .hide-payment {
      position: absolute;
      top: 43px;
      right: 0;
      color: $green;
      font-weight: bold;
      cursor: pointer;
    }
    .label-popular {
      position: absolute;
      top: 28px;
      right: 16px;
      background: rgba(221, 78, 82, 0.1);
      border-radius: 2px;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      padding: 2px 6px;
      color: $green;
    }
    .checkbox-selected {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      margin-right: 17px;
      position: relative;
      left: 1px;
      &:before {
        content: "";
        position: absolute;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        top: 3px;
        left: 3px;
      }
    }
    &.brown {
      .checkbox-selected {
        border: 1px solid $brown;
        &:before {
          background: $brown;
        }
      }
      .active {
        border: 2px solid $brown;
      }
      .btn-upgrade {
        &.selected,
        &:hover {
          background: $brown;
        }
      }
    }
    &.green {
      position: relative;
      top: -24px;
      .checkbox-selected {
        border: 1px solid $green;
        &:before {
          background: $green;
        }
      }
      .active {
        border: 2px solid $green;
      }
      .btn-upgrade {
        &.selected,
        &:hover {
          background: $green;
        }
      }
    }
    &.red {
      .checkbox-selected {
        border: 1px solid $red;
        &:before {
          background: $red;
        }
      }
      .active {
        border: 2px solid $red;
      }
      .btn-upgrade {
        &.selected,
        &:hover {
          background: $red;
        }
      }
    }
    &.blue {
      .checkbox-selected {
        border: 1px solid $blue;
        &:before {
          background: $blue;
        }
      }
      .active {
        border: 2px solid $blue;
      }
      .btn-upgrade {
        &.selected,
        &:hover {
          background: $blue;
        }
      }
    }
    .title {
      font-family: 'Playfair Display', serif;
      line-height: normal;
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 18px;
    }
    .option {
      border: 1px solid $border;
      padding: 5px 10px;
      font-size: 20px;
      line-height: 35px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 15px;
      position: relative;
      cursor: pointer;
      .label-save {
        position: absolute;
        top: 13px;
        right: 10px;
        background: $grey_vlight;
        border-radius: 2px;
        font-size: 14px;
        line-height: 16px;
        padding: 2px 6px;
      }
      img {
        margin-right: 13px;
        width: 22px;
      }
      span {
        margin-left: 4px;
        font-size: 14px;
        line-height: 20px;
        color: $grey_light;
      }
    }
    .options {
      padding: 12px 0;
      min-height: 275px;
      li {
        line-height: 24px;
        font-size: 16px;
        color: $black;
        text-align: left;
        padding-left: 40px;
        position: relative;
        margin-bottom: 7px;
        img {
          position: absolute;
          top: -1px;
          left: 0px;
          display: block;
          width: 24px;
          height: 24px;
        }
      }
    }
    .btn-upgrade {
      display: flex;
      height: 48px;
      background: $border;
      color: $white;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold;
      border-radius: 100px;
      cursor: pointer;
    }
  }
  .payment-box {
    background: $grey_mlight;
    padding: 32px;
    width: 448px;
    .title {
      font-size: 24px;
      line-height: 42px;
      margin-bottom: 16px;
    }
    .option {
      border: 1px solid $border;
      background: $white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      padding: 0 10px;
      margin-bottom: 16px;
      cursor: pointer;
      &.selected {
        border: 2px solid $black;
      }
      &__left,
      &__right {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &__left {
        img {
          margin-right: 11px;
        }
      }
      &__right {
        img {
          margin-left: 8px;
        }
      }
      .coming {
        background: rgba(221, 78, 82, 0.1);
        border-radius: 2px;
        font-size: 14px;
        line-height: 16px;
        padding: 2px 6px;
        color: $red;
        margin-left: 10px;
      }
    }
    .paypal-box {
      margin-top: 24px;
    }
    .step {
      font-size: 16px;
      margin-bottom: 5px;
    }
    .create-account-box {
      .header-trial {
        font-size: 24px;
        line-height: 42px;
        margin-bottom: 16px;
        padding-top: 2px;
      }
      input {
        width: 100%;
        background-color: $white;
        &:focus,
        &.filled {
          padding: 16px;
        }
      }
      .login {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 26px;
        span {
          padding-left: 5px;
          color: $red;
          cursor: pointer;
        }
      }
      .label {
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        margin-bottom: 8px;
      }
      .submit {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &.jazz {
          background: $blue;
        }
      }
      .loading-icon {
        position: absolute;
      }
      .terms {
        font-size: 14px;
      }
    }
  }
  .info-payment {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    margin-bottom: 24px;
    &__left,
    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__left {
      img {
        margin-right: 10px;
      }
    }
  }
  .stripe-box {
    .row-form {
      margin-bottom: 16px;
      position: relative;
    }
    .label {
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      margin-bottom: 8px;
    }
    input {
      font-family: "Roboto", sans-serif;
      height: 48px;
      border: 1px solid $border;
      padding: 0 12px;
      display: flex;
      align-items: center;
      width: 100%;
      &::placeholder {
        color: $border;
      }
    }
  }
  .input-white {
    font-family: "Roboto", sans-serif;
    background: $white;
    border: 1px solid $border;
    padding: 15px 12px;
    width: 100%;
    position: relative;
    &.error {
      border-color: $red;
    }
    &.cvc {
      max-width: 184px;
    }
  }
  .error-msg {
    font-size: 12px;
    line-height: 16px;
    color: $red;
    margin-top: 5px;
  }
  .green-tick {
    position: absolute;
    right: 10px;
    bottom: 17px;
  }
  .cvc-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    .input-white {
      margin-right: 16px;
    }
    .card {
      margin-right: 8px;
      border: 1px solid $grey_light;
      border-radius: 4px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 4px;
      font-size: 14px;
      color: $grey_light;
      width: 56px;
      height: 40px;
      position: relative;
      &.bar {
        &:before {
          content: "";
          position: absolute;
          left: 0px;
          width: 100%;
          height: 8px;
          background: $grey_light;
          top: 7px;
        }
      }
    }
  }
  .small-label {
    margin-top: 8px;
    font-size: 14px;
    line-height: 16px;
    color: $grey_light;
  }
  .button-submit {
    margin-top: 24px;
    background: $red;
    height: 48px;
    width: 100%;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    color: $white;
    cursor: pointer;
    border: none;
    outline: none;
    > div {
      margin-top: -7px;
    }
    &.jazz {
      background: $blue;
    }
    &.disabled {
      background: $grey_light;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .info-text {
    color: $grey_light;
    font-size: 14px;
    line-height: 20px;
    margin-top: 24px;
  }
  .red-text {
    font-size: 14px;
    line-height: 20px;
    color: $red;
    cursor: pointer;
    &.jazz {
      color: $blue;
    }
  }
  .box-error {
    background: rgba(221, 78, 82, 0.1);
    border: 1px solid $red;
    margin-bottom: 40px;
    padding: 16px 18px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 864px;
    .alert {
      margin-right: 18px;
    }
    .head-error {
      font-size: 16px;
      line-height: 23px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .sub-error {
      font-size: 14px;
      line-height: 20px;
      a {
        color: $red;
        text-decoration: none;
        margin-left: 5px;
      }
    }
  }
  .link-benefits {
    color: $red;
    display: none;
  }
}
.paypal-button {
  background: $yellow;
  height: 45px;
  border-radius: 23px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.05);
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  img {
    margin-left: 7px;
    height: 21px;
  }
}
.loader-paypal {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

// Jazz

.payment {
  .payment-box {
    .create-account-box.jazz {
      .login {
        span {
          color: $blue;
        }
      }
      .terms {
        span {
          color: $blue;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .payment {
    .subscription-info-box {
      margin-right: 25px;
      width: 45%;
    }
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .payment {
    padding: 20px 0 0 0;
    .content {
      flex-direction: column;
    }
    .subscription-info-box {
      margin: 0;
      width: 100%;
      .box {
        top: 0;
        padding-top: 0px;
      }
      .hide-payment {
        top: 12px;
      }
    }
    .box {
      max-width: 100%;
      .options {
        height: 0px;
        overflow: hidden;
        min-height: 0px;
        padding: 0;
        &.show-benefits {
          height: auto;
          min-height: 0px;
          margin-bottom: 15px;
        }
      }
    }
    .payment-box {
      width: calc(100% + 32px);
      padding: 20px;
      margin-left: -16px;
    }
    .link-benefits {
      display: inline-block;
      padding: 10px 0 20px 0;
      cursor: pointer;
    }
  }
}

.jazz-popup {
  margin: -20px;
  max-width: 640px;
  .close-popup {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
  .header {
    background: rgba(72, 102, 224, 0.1);
  }
  &__content {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .head-txt {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    line-height: 38px;
    max-width: 432px;
    text-align: center;
    margin-bottom: 20px;
  }
  .subtext {
    max-width: 284px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 32px;
  }
  .button-blue {
    background: #4866e0;
    border-radius: 100px;
    min-width: 264px;
    height: 48px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  .logo-foot {
    width: 100%;
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .jazz-popup {
    .logo-foot {
      max-width: 100%;
      width: 100%;
    }
    .head-txt {
      font-size: 26px;
    }
    .close-popup {
      display: block;
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
}

@import "./constants.scss";

.landing-page {
  &__head {
    background: url(../assets/images/mainpage.jpg) top center no-repeat;
    min-height: 554px;
  }
  .content-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 32px 0;
    &__left {
      color: $white;
      .button-red {
        max-width: 270px;
      }
    }
  }
  .header-text {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-style: normal;
    font-size: 56px;
    line-height: normal;
    padding-bottom: 20px;
  }
  .subheader-text {
    line-height: 35px;
    font-size: 20px;
    padding-bottom: 40px;
    max-width: 448px;
  }
  .bottom-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
  }
}

@media only screen and (max-width: $breakpoint_1024) {
  .landing-page {
    .content-head {
      &__left {
        width: 50%;
      }
      &__right {
        width: 47%;
      }
    }
    .header-text {
      font-size: 55px;
    }
    .subheader-text {
      br {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .landing-page {
    .header-text {
      font-size: 45px;
    }
    .subheader-text {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .landing-page {
    .header-text {
      font-size: 40px;
    }
    .subheader-text {
      font-size: 16px;
      line-height: 23px;
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .landing-page {
    &__head {
      background: url(../assets/images/mainpage_mobile.jpg) top left no-repeat;
      background-color: $grey_mlight;
      min-height: 640px;
    }
    .content-head {
      padding-top: 128px;
      flex-direction: column;
      &__left {
        width: 100%;
        padding-bottom: 72px;
      }
      &__right {
        width: 100%;
      }
    }
    .subheader-text {
      padding-bottom: 30px;
    }
  }
}

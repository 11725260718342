.person-page {
  .hero-container {
    position: relative;
    color: $black;
    min-height: 200px;

    .content-wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      max-width: 1120px;
      margin: 0px auto;

      @media only screen and (max-width: $breakpoint_880) {
        flex-direction: column;
      }

      .left-section,
      .right-section {
        padding: 63px 0px 80px 0px;
        min-width: 50%;
        @media only screen and (max-width: $breakpoint_880) {
          margin: 0px 0px;
          padding: 0px;
        }
      }
      .left-section {
        align-self: center;

        @media only screen and (max-width: $breakpoint_880) {
          padding-bottom: 60px;
          width: 100%;
          text-align: center;
          .actions-wrapper {
            margin: 0 auto;
          }
        }

        .details-wrapper {
          .personImage {
            width: 171px;
            height: 171px;
            border-radius: 50%;
            object-fit: cover;
          }

          @media only screen and (max-width: $breakpoint_880) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .personImage {
              margin-bottom: 20px;
              width: 171px;
              height: 171px;
            }
          }

          .shareAllButton {
            &:hover svg {
              path {
                fill: $grey_vlight;
              }
            }
          }

          .text-wrapper {
            padding-left: 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            z-index: 2;
            .name {
              font-family: "Playfair Display", serif;
              font-weight: 600;
              font-size: 28px;
              line-height: 38px;
              margin-bottom: 10px;

              @media only screen and (max-width: $breakpoint_880) {
                font-size: 24px;
                line-height: 28px;
              }
            }

            .other-details {
              font-family: "Roboto", sans-serif;
              font-size: 16px;
              line-height: 28px;
              color: #8f99aa;
              padding-bottom: 7px;
            }

            .actions-wrapper {
              display: flex;
              flex-direction: row;
              align-items: flex-end;

              & > div {
                margin-right: 10px;
                line-height: 20px;
              }
            }
          }
        }
      }
      .right-section {
        z-index: 1;

        .quotes-wrapper {
          .card-top-icon {
            top: -13px;
          }
          .arrows-container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            .arrow-right {
              padding-left: 26px;
              cursor: pointer;
              flex-shrink: 0;

              @media only screen and (max-width: $breakpoint_880) {
                padding: unset;
              }
            }

            .arrow-left {
              padding-right: 26px;
              cursor: pointer;
              flex-shrink: 0;

              @media only screen and (max-width: $breakpoint_880) {
                padding: unset;
              }
            }

            .arrow-left,
            .arrow-right {
              &.hidden {
                opacity: 0;
                cursor: default;
              }
            }
          }
        }
      }
    }
  }

  .person-content {
    position: relative;

    .tabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      box-shadow: inset 0px -1px 0px #dcdfe4;
      justify-content: center;
      max-width: 1120px;
      margin: 0px auto;
      user-select: none;
      overflow-x: auto;
      @media only screen and (max-width: $breakpoint_658) {
        justify-content: unset;
      }
      a,
      div {
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 72px;
        margin: 0 32px;
        color: $grey_light;
        font-family: "Playfair Display", serif;
        font-weight: 600;
        font-style: normal;
        font-size: 22px;
        position: relative;
        cursor: pointer;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        animation: fadeIn 1s ease both;
        animation-iteration-count: 1;

        &:visited {
          text-decoration: none;
        }

        &.active {
          color: $black;
          &:before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            height: 3px;
            width: 100%;
            background: $red;
          }
        }

        @media only screen and (max-width: $breakpoint_880) {
          font-family: "Playfair Display", serif;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }

    .tabContainer {
      padding-bottom: 64px;

      .showTab {
        display: block;
      }

      .hideTab {
        display: none;
      }

      .discover-tab {
        .person-section {
          padding: 48px 20px 0px 20px;
        }
        .recent-albums {
          display: block;
          max-width: 1120px;
          margin: 0px auto;

          @media only screen and (max-width: $breakpoint_880) {
            flex-direction: column;
          }
        }
        .person-section {
          .content {
            display: flex;
            flex-direction: row;
            font-family: "Roboto", sans-serif;
            max-width: 1120px;
            margin: 0px auto;

            &.flex-column {
              flex-direction: column;
              .content {
                flex-direction: column;
              }
            }

            &.padded {
              padding: 50px 0 0 0;
            }

            @media only screen and (max-width: $breakpoint_880) {
              flex-direction: column;
            }

            .title-box {
              font-family: "Playfair Display", serif;
              line-height: 25px;
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 32px;
            }

            .discover-left-section {
              width: 50%;
              padding-right: 50px;
              display: flex;
              flex-direction: column;

              &.full-width {
                width: 100%;
                padding: 8px;
                .content {
                  width: 100%;
                }
              }
              @media only screen and (max-width: $breakpoint_880) {
                width: unset;
                padding: unset;
              }
            }

            .discover-right-section {
              width: 50%;

              @media only screen and (max-width: $breakpoint_880) {
                width: unset;
                padding-top: 40px;
              }

              .bio {
                font-size: 16px;
                color: $black;
                line-height: 28px;
                max-height: unset;

                div,
                font {
                  font-size: 16px;
                  color: $black;
                  line-height: 28px;
                }

                &.text-truncate {
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-height: 500px;
                }
              }

              .show-more {
                padding-top: 16px;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                cursor: pointer;
                color: #ebae71;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .related-section {
          .related-wrapper {
            max-width: 1120px;
            margin: 0px auto;

            .featured,
            .appears-on-section {
              padding: 25px 0px;
            }
          }
        }
      }

      .works-tab {
        .content-works {
          max-width: 740px;
          min-height: 100vh;
          padding-bottom: 50px;
          .year {
            color: $grey_light;
            line-height: 23px;
          }
        }

        .search-container {
          height: 64px;
          margin: 30px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          max-width: 740px;

          &.only-button {
            justify-content: flex-end;
          }

          &__left,
          &__right {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          &__search-box {
            width: 100%;
          }

          .input-search {
            width: 100%;
            height: 29px;
            border: none;
            font-size: 16px;
            line-height: 28px;
            color: $black;
            margin: 0;
            padding: 0;
          }
          .icon-enter {
            cursor: pointer;
          }
          .icon-close-search {
            margin-left: 40px;
            margin-right: 10px;
            cursor: pointer;
          }
          .button-search {
            border: 1px solid $black;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 4px 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
              margin-right: 8px;
              width: 25px;
              height: 25px;
            }
          }
        }

        .list-of-works {
          li {
            background: $white;
            border-radius: 4px;
            padding: 10px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            &:hover {
              background: rgba(221, 78, 82, 0.1);
            }
            img {
              cursor: pointer;
              margin: auto 16px;
            }
            .info-works {
              cursor: pointer;
            }

            .arrow {
              margin-left: auto;
            }
          }
          .work-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;
            color: inherit;
          }
        }

        .icon-play {
          min-width: 40px;
          min-height: 40px;
          margin-right: 10px;
          cursor: pointer;
        }
        .work-name {
          font-weight: bold;
          line-height: 23px;
          text-transform: uppercase;
        }
        .content-works {
          max-width: 740px;
          min-height: 100vh;
          padding-bottom: 50px;
          .year {
            color: $grey_light;
            line-height: 23px;
          }
        }
        @media only screen and (max-width: $breakpoint_768) {
          .list-of-works {
            li {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }

      .recordings-tab {
        display: flex;
        flex-direction: column;
        font-family: "Roboto", sans-serif;
        min-height: 100vh;
        margin: 0px auto;
        max-width: 740px;

        .search-container {
          margin: 0px 0px;
          padding: 45px 0px 32px 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;

          @media only screen and (max-width: $breakpoint_768) {
            margin: 0px 25px;
          }

          &.only-button {
            justify-content: flex-end;
          }
          &__left,
          &__right {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          &__search-box {
            width: 100%;
          }
          .input-search {
            width: 100%;
            height: 29px;
            border: none;
            font-size: 16px;
            line-height: 28px;
            color: $black;
            margin: 0;
            padding: 0;
          }
          .icon-enter {
            cursor: pointer;
          }
          .icon-close-search {
            margin-left: 40px;
            margin-right: 10px;
            cursor: pointer;
          }
          .button-search {
            border: 1px solid $black;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 4px 8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
              margin-right: 8px;
              width: 25px;
              height: 25px;
            }
          }
        }

        .recordings-list {
          margin: 0px 0px;

          @media only screen and (max-width: $breakpoint_768) {
            margin: 0px 0px;
          }

          li {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 8px;
            min-height: 96px;
            align-items: center;

            &:hover {
              background: rgba(221, 78, 82, 0.1);
            }

            .recording-left {
              display: flex;
              flex-direction: row;
              min-height: inherit;

              .icon-play {
                width: 40px;
                height: 40px;
                margin: auto 16px;
                cursor: pointer;
              }

              .info-recording-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;

                a {
                  font-size: 14px;
                  color: #8f99aa;
                }

                .info-recordings {
                  font-family: "Roboto", sans-serif;
                  font-size: 16px;
                  text-decoration: none;
                  color: inherit;

                  .name {
                    font-weight: bold;
                  }

                  .year {
                    font-size: 14px;
                    color: #8f99aa;
                    padding: 10px 0px;
                  }
                }
              }
            }

            .recording-right {
              padding: 20px 20px;
            }
          }
        }
      }

      .albums-tab {
        display: flex;
        flex-direction: column;
        font-family: "Roboto", sans-serif;
        min-height: 100vh;
        margin: 0px auto;
        max-width: 1120px;
        padding-top: 30px;

        > ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;

          > a {
            margin: 10px 20px 20px 20px;
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            text-decoration: none;
            max-width: 200px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-self: flex-start;

            @media only screen and (max-width: $breakpoint_518) {
              max-width: 80%;
            }

            > img {
              height: 200px;
              width: 200px;
              margin-bottom: 20px;

              @media only screen and (max-width: $breakpoint_518) {
                max-width: 80%;
                margin: auto;
              }
            }

            .album-title {
              font-weight: 700;
              line-height: 19px;
              text-align: left;
              color: black;
              margin-bottom: 5px;

              @media only screen and (max-width: $breakpoint_518) {
                text-align: center;
              }
            }

            .album-year {
              font-weight: 400;
              line-height: 22px;
              text-align: left;
              color: #8f99aa;

              @media only screen and (max-width: $breakpoint_518) {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  .loading {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .albums-list {
    .default-stack-container {
      li {
        @media only screen and (max-width: $breakpoint_658) {
          position: relative;
          margin-bottom: 10px;
          margin-right: 0;
          height: 100px;
          > span:first-child {
            right: 0;
            margin-right: 5px;
            font-size: 12px;
          }
          a {
            padding: 10px;
            display: flex;
            flex-direction: row;
            font-size: $size_18;
            .image {
              height: 76px;
              width: 76px;
              margin-bottom: 0;
              margin-right: 5px;
              .image-album {
                height: 76px;
                min-width: 76px;
                object-fit: cover;
              }
            }
            .album-details {
              padding: 7px 0;
              .name {
                font-size: $size_16;
              }
              .artist {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

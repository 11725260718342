.video-container {
  background: $black;

  .content {
    text-align: center;
    display: flex;
    align-items: center;
    max-width: 1400px;
    justify-content: center;
    .youtube-container {
      margin: auto;
    }
  }
  iframe {
    display: block;
    margin: 0 auto;
  }
  #player {
    position: absolute;
    top: 0;
  }
}
.video-container-image {
  background: $black;
  .content {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  img {
    width: 100%;
    max-width: 800px;
    display: block;
    margin: 0 auto;
  }
  .info-subscription {
    position: absolute;
    max-width: 800px;
    width: 100%;
    height: 100%;
    background: rgba(20, 17, 64, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .text {
    color: $white;
    font-size: 20px;
    line-height: 35px;
    max-width: 550px;
  }
  .btn-upgrade {
    background: $red;
    color: $white;
    font-weight: bold;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    border-radius: 100px;
    margin-top: 24px;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}
.video-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 54px 30px 0 30px;
  .title {
    font-family: "Playfair Display", serif;
    font-size: 42px;
    line-height: 69px;
  }
  .subheader {
    font-size: 16px;
    line-height: 28px;
    color: $grey_light;
    margin-top: 24px;
    margin-bottom: 40px;
  }
  .desc {
    font-size: 18px;
    line-height: 32px;
    p {
      margin-bottom: 16px;
    }
  }
  .programme-box {
    margin: 40px 0;
    background: $grey_mlight;
    padding: 24px 32px;
    .title-programme {
      font-size: 20px;
      line-height: 28px;
      color: $grey_light;
      margin-bottom: 12px;
    }
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 0;
      span {
        min-width: 26px;
        text-align: center;
        margin-right: 18px;
        color: $grey_light;
      }
      .composer-name {
        font-weight: bold;
      }
      .track-title {
        margin-top: 8px;
        line-height: 20px;
      }
    }
  }
  .video-info {
    box-shadow: inset 0px -1px 0px $grey_vlight, inset 0px 1px 0px $grey_vlight;
    padding: 24px 32px;
    margin-bottom: 64px;
    .row {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
      .label {
        color: $grey_light;
        margin-bottom: 8px;
        font-size: 14px;
      }
    }
  }
}
.info-upgrade {
  background: $black;
  padding: 40px 0;
  .content {
    display: flex;
    flex-direction: column;
  }
  .upgrade-text {
    font-size: 20px;
    line-height: 35px;
    color: $white;
    text-align: center;
    max-width: 566px;
    margin: 0 auto;
  }
  .upsell-cta-button {
    border-radius: 100px;
    min-width: 200px;
    height: 40px;
    margin: 0 auto;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}
.fullscreen-widget {
  &.side-widget {
    z-index: 1001;
  }
  @media only screen and (max-width: $breakpoint_768) {
    display: none !important;
  }

  &.disabled {
    z-index: -1;
  }

  .modal-container {
    background: linear-gradient(0deg, rgba(255, 225, 225, 1) 0%, rgba(255, 255, 255, 1) 100%);
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 60vw;
    height: 600px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
    cursor: default;
    transition-property: min-width, height;
    transition-duration: 0.7s, 0.7s;
    transition-delay: 0.7s, 0s;

    &.fullscreen {
      min-width: 99vw;
      height: 100vh;
      background: black;
      padding: 0 0;
      border-radius: 0;
    }

    .confirmation-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px;
      opacity: 1;
      transition-property: opacity;
      transition-duration: 0.2s;
      background-image: url("../assets/images/Christmas/more_stars.svg");
      background-size: contain;
      background-repeat: no-repeat;
      max-width: 600px;
      margin: auto;

      &.hidden {
        opacity: 0;
      }
    }

    .close-button {
      position: absolute;
      right: 20px;
      top: 20px;
      background-color: inherit;
      border: 0px;
      cursor: pointer;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
.videos {
  .also-like {
    .header {
      margin-bottom: 32px;
    }
  }
}

@media only screen and (max-width: $breakpoint_1024) {
  .video-detail {
    .title {
      font-size: 40px;
      line-height: 60px;
    }
  }
  .videos {
    .also-like {
      .list-recommend {
        flex-wrap: wrap;
      }
      li {
        margin-bottom: 20px;
        position: relative;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .video-container {
    .content {
      padding: 0;
    }
  }
  .video-detail {
    .title {
      font-size: 32px;
      line-height: 38px;
    }
    .video-info {
      margin-bottom: 32px;
    }
  }
}

@media only screen and (max-width: 799px) {
  .video-container {
    background: transparent;
    .content {
      flex-direction: column;

      &.youtube {
        display: block;
      }
    }
    iframe {
      width: 100%;
    }
  }
  .video-detail {
    padding: 32px 0px;
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .videos {
    .also-like {
      padding: 32px 0;
      li {
        width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

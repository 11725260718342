@import "./constants.scss";

.subscriptions-box {
  padding: 120px 0 90px 0;
  .header-txt {
    text-align: center;
    padding-bottom: 150px;
  }
  .boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .box {
    width: 336px;
    max-width: 336px;
    position: relative;
    .head {
      height: 88px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Playfair Display', serif;
      font-size: 28px;
      color: $white;
      &.brown {
        background: $brown;
      }
      &.red {
        background: $red;
      }
      &.green {
        background: $green;
      }
      &.blue {
        background: $blue;
      }
    }
    .content-box {
      box-shadow: 0px 0px 8px $grey_vlight;
      background: $white;
      padding: 32px;
      text-align: center;
      .price {
        line-height: 45px;
        font-size: 32px;
        padding: 5px 0 3px 0;
      }
      .per {
        color: $grey_light;
        line-height: 20px;
        font-size: 14px;
      }
      .options {
        padding: 25px 0;
        li {
          line-height: 24px;
          font-size: 16px;
          color: $black;
          text-align: left;
          padding-left: 40px;
          position: relative;
          margin-bottom: 7px;
          img {
            position: absolute;
            top: -1px;
            left: 0px;
            display: block;
            width: 24px;
            height: 24px;
          }
          &.inactive {
            opacity: 0.25;
          }
        }
      }
    }
  }
  .box-classical {
    z-index: 1;
    left: 7px;
  }
  .box-all-vialma {
    z-index: 3;
    top: -72px;
  }
  .box-jazz {
    z-index: 2;
    left: -7px;
  }
  .button {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 100px;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  .button-brown {
    color: $brown;
    border: 1px solid $brown;
    &:hover {
      background: $brown;
      color: $white;
    }
  }
  .button-blue {
    background-color: $white;
    color: $blue;
    border: 1px solid $blue;
    &:hover {
      background: $blue;
      color: $white;
    }
  }
  .button-green {
    color: $green;
    border: 1px solid $green;
    &:hover {
      background: $green;
      color: $white;
    }
  }
}

@media only screen and (max-width: $breakpoint_1200) {
  .subscriptions-box {
    .boxes {
      padding: 0 30px;
    }
    .box {
      width: 100%;
    }
  }
}

@media only screen and (max-width: $breakpoint_1024) {
  .subscriptions-box {
    .box {
      .content-box {
        padding: 24px;
        .options {
          padding-bottom: 10px;
          li {
            font-size: 14px;
            padding-left: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .subscriptions-box {
    padding: 40px 0;
    .header-txt {
      padding-bottom: 40px;
    }
    .boxes {
      flex-direction: column;
      padding: 0 16px;
    }
    .box {
      max-width: 100%;
      position: static;
      margin-bottom: 16px;
      .head {
        height: 64px;
        font-size: 24px;
      }
      .content-box {
        .options {
          li {
            font-size: 16px;
          }
        }
      }
    }
  }
}



.ComposersToDiscover {
  margin-bottom: 80px;

  &__SectionTitle {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    margin-bottom: 48px;

    @media only screen and (max-width: $breakpoint_768) {
      margin-bottom: 20px;
    }
  }

  &__Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: $breakpoint_768) {
      flex-direction: column;
    }
  }
  &__ComposerBox {
    display: flex;
    flex-direction: column;
    width: 23%;
    background: $white;
    border-radius: 8px;
    padding: 15px;
    position: relative;
    cursor: pointer;
    color: $black;
    border: none;
    text-align: initial;
    font-size: 16px;
    outline: none;
    font-family: "Roboto", sans-serif;

    @media only screen and (max-width: $breakpoint_768) {
      width: 100%;
      margin-bottom: 20px;
    }

    &:hover {
      box-shadow: 0px 4px 8px rgba(143, 153, 170, 0.5);
      text-decoration: none;
    }

    .border-bottom {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 8px;
      border-radius: 0 0 8px 8px;
    }

    &.red {
      .border-bottom {
        background: $red;
      }
    }

    &.blue {
      .border-bottom {
        background: $blue;
      }
    }
  }
}

.Composer {
  display: flex;
  flex-direction: column;
  padding: 10px 0 12px 0;

  @media only screen and (max-width: $breakpoint_768) {
    flex-direction: row;
  }

  &__ComposerPhoto {
    img {
      height: 55px;
      width: 55px;
      border-radius: 50%;
      border: 3px solid $grey_vlight;
      margin: 0px 16px 15px 0px;
    }
  }


  &__ComposerInfo {
    min-height: 70px;
    &__Name {
      line-height: 23px;
      color: $black;
      font-weight: bold;
      margin-bottom: 5px;
    }
  
    &__Details {
      line-height: 23px;
      color: $grey_light;
    }
  
    &__Epoque {
      color: $grey_light;
      margin-top: 4px;
      span {
        margin-right: 5px;
      }
    }
  }
}

.Citation {
  padding: 10px 0px;
  &::before, &::after {
    font-family: 'Playfair Display', serif;
    width: 10px;
    height: 10px;
    content: '\0022';
  }
}
.QuizSection {
  width: 100%;
  height: 288px;
  margin-bottom: 48px;
  text-align: center;
  font-family: "Roboto";
  color: white;
  position: relative;

  @media only screen and (max-width: $breakpoint_768) {
    height: 450px;
  }

  &__Image {
    width: 100%;
    height: 288px;
    object-fit: cover;
    object-position: 50% 50%;
    filter: brightness(50%);

    @media only screen and (max-width: $breakpoint_768) {
      height: 450px;
    }
  }

  &__Details {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    font-style: normal;

    &__SectionTitle {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      margin-top: 20px;
      margin-bottom: 8px;

      @media only screen and (max-width: $breakpoint_768) {
        font-size: 24px;
      }
    }

    &__Description {
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      margin: auto;
      margin-top: 8px;
      margin-bottom: 12px;

      @media only screen and (max-width: $breakpoint_768) {
        margin-bottom: 40px;
        font-size: 16px;
      }
    }

    &__CTA {
      background-color: #ffffff;
      border: none;
      border-radius: 38px;
      display: inline-block;
      cursor: pointer;
      color: #000000;
      font-weight: 500;
      font-size: 16px;
      font-family: "Roboto";
      line-height: 19px;
      padding: 12px 23px;
      letter-spacing: 0.01em;
      margin-top: 12px;

      &:hover {
        background-color: #f0edf0;
      }

      &:focus {
        outline: 0;
      }
    }
  }
}

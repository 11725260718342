@import "./constants.scss";

.download-app {
  width: 860px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 120px;
  .header-txt {
    max-width: 448px;
  }
  &__info {
    padding-top: 70px;
    .subheader {
      padding-top: 30px;
      line-height: 28px;
      font-size: 16px;
      color: $black;
      max-width: 384px;
    }
    .button-red {
      width: 240px;
      margin-top: 35px;
    }
  }
  &__image {
    img {
      max-width: 300px;
    }
  }
  .apps {
    padding-top: 72px;
    display: flex;
    flex-direction: row;
    &__item {
      margin-right: 40px;

      a {
        .app-store-logo {
          width: 120px;
          height: 40px;
        }
        .google-play-logo {
          width: 135px;
          height: 40px;
        }
      }
    }
  }
  .stars {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    .star {
      margin-right: 5px;
      width: 16px;
      height: 16px;
    }
  }
  .rank {
    margin-left: 5px;
    padding-top: 1px;
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .download-app {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    .header-txt {
      font-size: 32px;
    }
    .header-txt,
    .subheader {
      br {
        display: none;
      }
    }
    &__info {
      width: 50%;
    }
    &__image {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      img {
        max-width: 300px;
      }
    }
    .apps {
      &__item {
        margin-right: 20px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .download-app {
    flex-direction: column;
    text-align: center;
    padding-bottom: 40px;
    .header-txt {
      font-size: 40px;
      text-align: center;
    }
    .button-red {
      margin-left: auto;
      margin-right: auto;
    }
    &__info {
      width: 100%;
      padding-top: 0;
    }
    &__image {
      width: 100%;
      justify-content: center;
    }
    .apps {
      justify-content: center;
      padding: 40px 0;
      &__item {
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
          margin-left: 20px;
        }
      }
    }
  }
}

.download-app-wrapper {
  .apps {
    padding-top: 72px;
    display: flex;
    flex-direction: row;
    &__item {
      margin-right: 40px;

      a {
        .app-store-logo {
          width: 120px;
          height: 40px;
        }
        .google-play-logo {
          width: 135px;
          height: 40px;
        }
      }
    }
  }
  .stars {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    .star {
      margin-right: 5px;
      width: 16px;
      height: 16px;
    }
  }
  .rank {
    margin-left: 5px;
    padding-top: 1px;
  }

  @media only screen and (max-width: $breakpoint_658) {
    flex-direction: column;
    text-align: center;
    padding-bottom: 40px;
    .header-txt {
      font-size: 40px;
      text-align: center;
    }
    .button-red {
      margin-left: auto;
      margin-right: auto;
    }
    &__info {
      width: 100%;
      padding-top: 0;
    }
    &__image {
      width: 100%;
      justify-content: center;
    }
    .apps {
      justify-content: center;
      padding: 40px 0;
      &__item {
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
          margin-left: 20px;
        }
      }
    }
  }
}

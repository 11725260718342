.christmas-banner {
  background: $red;
  .content {
    width: 1120px;
    min-height: 250px;
    position: relative;
    display: flex;
    align-items: center;
  }
  #snow {
    position: absolute;
    top: 0;
    left: 32%;
    right: 0;
    bottom: 0;
    width: 68%;
    height: 250px;
  }
  #source {
    display: none;
  }
  .text-header {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-style: normal;
    font-size: 42px;
    color: $white;
    margin-bottom: 24px;
  }
  .subheader {
    font-size: 16px;
    line-height: 28px;
    color: $white;
    max-width: 350px;
  }
}

.playlist-list {
  .loading-list {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-list {
    padding: 23px 0;
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    .content {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .button-red,
      .button-red-border {
        display: flex;
        min-width: 160px;
        height: 40px;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        cursor: pointer;
        border: 1px solid $red;
        border-radius: 100px;
      }
      .button-red-border {
        color: $red;
      }
      .button-red {
        color: $white;
        background: $red;
      }
    }
    span {
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      font-weight: normal;
      color: $grey_light;
      margin-right: 18px;
      cursor: pointer;
    }

    &__right {
      width: 50%;
      span {
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        font-weight: normal;
        color: $grey_light;
        margin-right: 18px;
        cursor: pointer;
      }

      .no-result {
        padding-top: 40px;
        text-align: center;
      }
      .list-of-songs {
        padding: 64px 0;
        li {
          padding: 16px 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: $black;
          border-radius: 4px;
          &.active {
            .track-title {
              color: $red;
            }
          }
          &:hover {
            background: rgba(221, 78, 82, 0.1);
            .index {
              span {
                display: none;
              }
              .icon-play-circle-red {
                display: block;
              }
            }
          }
          &.disabled {
            opacity: 0.5;
            .icon-more {
              display: none;
            }
            &:hover {
              background: none;
              .index {
                span {
                  display: block;
                }
                .icon-play-circle-red {
                  display: none;
                }
              }
            }
          }
          .icon-play-circle-red {
            width: 40px;
            height: 40px;
            display: none;
            cursor: pointer;
          }
        }
        &__left {
          cursor: pointer;
        }
        &__left,
        &__right {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        &__right {
          color: $grey_light;
          line-height: 23px;
          padding-left: 50px;
        }
        .index {
          line-height: 23px;
          color: $grey_light;
          margin-right: 10px;
          min-width: 40px;
        }
        .info-songs {
          display: flex;
          flex-direction: column;
          min-height: 50px;
          justify-content: center;
          .row {
            display: flex;
            flex-direction: row;
          }
        }
        .composer {
          font-weight: bold;
          line-height: 23px;
          margin-right: 8px;
        }
        .track-title {
          line-height: 23px;
        }
        .performances {
          line-height: 23px;
          color: $grey_light;
          margin-top: 4px;
        }
        .icon-time {
          margin-right: 5px;
        }
        .icon-more,
        .icon-remove {
          margin-left: 18px;
          width: 32px;
          height: 32px;
          cursor: pointer;
        }
        .icon-remove {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
  .no-result {
    padding-top: 40px;
    text-align: center;
  }
  .list-of-songs {
    padding: 64px 0;
    li {
      padding: 16px 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: $black;
      border-radius: 4px;
      &.active {
        .track-title {
          color: $red;
        }
      }
      &:hover {
        background: rgba(221, 78, 82, 0.1);
        .index {
          span {
            display: none;
          }
          .icon-play-circle-red {
            display: block;
          }
        }
      }
      &.disabled {
        opacity: 0.5;
        .icon-more {
          display: none;
        }
        &:hover {
          background: none;
          .index {
            span {
              display: block;
            }
            .icon-play-circle-red {
              display: none;
            }
          }
        }
      }
      .icon-play-circle-red {
        width: 40px;
        height: 40px;
        display: none;
        cursor: pointer;
      }
    }
    &__left {
      cursor: pointer;
      max-width: 70%;
    }
    &__left,
    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__right {
      color: $grey_light;
      line-height: 23px;
      padding-left: 50px;
    }
    .index {
      line-height: 23px;
      color: $grey_light;
      margin-right: 10px;
      min-width: 40px;
    }
    .info-songs {
      display: flex;
      flex-direction: column;
      min-height: 50px;
      justify-content: center;
      .row {
        display: flex;
        flex-direction: row;
      }
    }
    .composer {
      font-weight: bold;
      line-height: 23px;
      margin-right: 8px;
    }
    .track-title {
      line-height: 23px;
    }
    .performances {
      line-height: 23px;
      color: $grey_light;
      margin-top: 4px;
    }
    .icon-time {
      margin-right: 5px;
    }
    .icon-more,
    .icon-remove {
      margin-left: 18px;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
    .icon-remove {
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
.remove-song-modal {
  padding: 30px;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  .button-red {
    width: 40%;
    margin: 24px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-close {
    display: none;
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .playlist-list {
    .list-of-songs {
      padding: 32px 0;
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .playlist-list {
    .list-of-songs {
      padding: 32px 0;
      .time-text,
      .icon-time {
        display: none;
      }
      &__right {
        padding-left: 5px;
      }
    }
  }
  .remove-song-modal {
    .icon-close {
      display: block;
      position: absolute;
      top: 16px;
      right: 16px;
    }
    .button-red {
      width: auto;
    }
  }
}

.playlist-list-image-container {
  position: relative;
  width: 576px;
  height: auto;
  object-fit: cover;
  @media only screen and (max-width: $breakpoint_880) {
    width: 288px;
    height: 200px;
  }

  img {
    position: absolute;
    z-index: 10;
    box-shadow: 0 1.5rem 4rem rgba($black, 0.4);
    float: right;
  }
  .default {
    background: $grey_vlight;
    box-shadow: 0 1.5rem 4rem rgba($black, 0.9);
  }

  &.children-1 {
    img {
      width: 300px;
      height: auto;
      @media only screen and (max-width: $breakpoint_880) {
        width: auto;
        height: 100%;
      }
    }
  }
  &.children-2 {
    img {
      height: auto;
      width: 50%;
      @media only screen and (max-width: $breakpoint_880) {
        width: 70%;
      }
    }
    .playlist-list-image-1 {
      top: 3rem;
      @media only screen and (max-width: $breakpoint_880) {
        top: 2rem;
      }
    }
    .playlist-list-image-2 {
      left: 4rem;
      top: 0rem;
      @media only screen and (max-width: $breakpoint_880) {
        position: absolute;
        top: 0rem;
        left: unset;
      }
    }
  }
  &.children-3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    img {
      width: 35%;
      height: auto;
      @media only screen and (max-width: $breakpoint_768) {
        width: 45%;
      }
    }
    .playlist-list-image-1 {
      right: 0px;
      top: 0px;
    }
    .playlist-list-image-2 {
      left: -5px;
      top: 10px;
    }
    .playlist-list-image-3 {
      bottom: 10px;
      left: -20%;
    }
  }
  &.children-4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    img {
      max-width: 35% !important;
      height: auto;
      margin-right: 0.4rem;
      margin-bottom: 0.4rem;
    }
  }
}

.playlist-recommendation-btn {
  min-width: 100px;
  > div {
    height: 15px;
    position: relative;
    display: flex;
  }
  @media only screen and (max-width: $breakpoint_658) {
    min-width: unset;
    margin-right: -5px;
  }
}

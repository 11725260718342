.search-page {
  height: 100%;
  .box-search {
    background: $grey_mlight;
    padding: 24px 0;

    &.search-active {
      background: $white;

      .search-result {
        @extend .bordered;
        position: relative;
        border-radius: $card_border_radius;
        padding-left: 10px;
        position: relative;
        @media only screen and (min-width: $breakpoint_880) {
          width: 50%;
        }
        img {
          position: absolute;
          right: 0;
        }
      }
      .active-search {
        position: relative;
        .search-label {
          color: $grey_light;
          position: absolute;
          top: -12px;
          font-size: 11px;
        }
      }
    }
  }
  .bar-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    input {
      margin: 0;
      width: 100%;
      height: 48px;
      padding: 10px 45px 10px 12px;
      &:focus {
        padding: 10px 12px;
      }
    }
    .button-red {
      width: 120px;
      margin: 0 0 0 16px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon-close-search {
      position: absolute;
      right: 135px;
      cursor: pointer;
    }
  }
  .search-result {
    height: 48px;
    display: flex;
    align-items: center;
    color: $grey_light;
    input,
    span {
      font-size: 20px;
      color: $black;
      margin-left: 8px;
      margin-right: 8px;
      position: relative;
      top: -1px;
    }
    input,
    input:focus {
      margin-bottom: 0;
      border: 0;
      height: 95%;
      padding: 0;
      width: 90%;
    }
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
.loading-search {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 324px);
}
.search-section {
  margin-top: 48px;
  &__head {
    font-family: "Playfair Display", serif;
    font-size: 22px;
    line-height: 29px;
    box-shadow: inset 0px -1px 0px #141140;
    padding: 16px 0;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      color: $red;
      cursor: pointer;
    }
    &.activePage {
      justify-content: center;
      margin-top: 48px;
      position: relative;
    }
    .back {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      position: absolute;
      left: 0;
      top: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        color: $black;
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.works-list {
  .work-title {
    text-transform: uppercase;
  }
}
.works-list,
.episodes-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0% 3%;
  @media only screen and (max-width: $breakpoint_658) {
    grid-template-columns: 1fr;
  }
  li {
    position: relative;
    border-radius: $card_border_radius;
    padding: 10px;
    margin-bottom: 8px;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    @extend .bordered;
    @extend .hover-darken;

    > a {
      display: block;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:hover {
        text-decoration: none;
      }
    }
    a > img {
      min-width: 48px;
      min-height: 48px;
      max-width: 48px;
      max-height: 48px;
      border-radius: 50%;
      margin-right: 12px;
    }
    .initials-box {
      min-width: 48px;
      min-height: 48px;
      max-width: 48px;
      max-height: 48px;
      border-radius: 50%;
      margin-right: 12px;
      border: 3px solid $grey_vlight;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $grey_vlight;
      font-size: 18px;
      color: $grey_light;
      text-transform: uppercase;
    }
    .info-works,
    .info-episodes {
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 8px;
        color: $black;
        &:last-child {
          padding-bottom: 0px;
        }
      }
      .composer-name,
      .podcast-name {
        font-weight: bold;
        margin-right: 8px;
      }
      .interpretations-count {
        color: $grey_light;
      }
    }
    .arrow-right {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -16px;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}

// Jazz
.search-page.jazz {
  .search-section {
    margin-top: 48px;
    &__head {
      span {
        color: $blue;
      }
    }
  }
  .bar-search {
    .button-red {
      background: $blue;
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .search-page {
    .bar-search {
      flex-direction: column;
      .button-red {
        margin: 16px 0 0 0;
      }
      .icon-close-search {
        top: 8px;
        right: -5px;
      }
    }
  }
  .search-section {
    &__head {
      .back {
        span {
          display: none;
        }
        img {
          position: relative;
          top: 3px;
        }
      }
    }
  }
}

.default-media-card-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px 3%;

  &.slider-component {
    .slick-slide > div > div {
      padding-right: 1%;
    }
  }
  @media only screen and (max-width: $breakpoint_1024) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media only screen and (max-width: $breakpoint_658) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

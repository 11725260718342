@import "./constants.scss";

.landing-page-radio {
  &__head {
    background: url(../assets/images/mainpage.jpg) top center no-repeat;
    min-height: 800px;
  }
  .logo {
    display: block;
    margin-bottom: 62px;
    width: 64px;
    height: 64px;
  }
  .content-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &__left {
      padding-top: 76px;
      color: $white;
    }
  }
  .logo-radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 20px;
    font-size: 16px;
    img {
      width: 120px;
      height: 120px;
      margin-right: 24px;
    }
  }
  .logo-radio-txt {
    max-width: 160px;
  }
  .header-text {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 48px;
    line-height: normal;
    padding-top: 48px;
    padding-bottom: 30px;
    max-width: 558px;
  }
  .subheader-text {
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
  }
  .free-trial-box {
    .header-trial {
      font-size: 21px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: $breakpoint_1200) {
  .landing-page-radio {
    .header-text {
      font-size: 57px;
    }
  }
}

@media only screen and (max-width: $breakpoint_1024) {
  .landing-page-radio {
    .content-head {
      &__left {
        width: 50%;
      }
      &__right {
        width: 47%;
      }
    }
    .header-text {
      font-size: 47px;
      br {
        display: none;
      }
    }
    .subheader-text {
      br {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .landing-page-radio {
    .header-text {
      font-size: 36px;
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .landing-page-radio {
    .logo {
      margin-bottom: 50px;
    }
    &__head {
      background: url(../assets/images/mainpage_mobile.jpg) top left no-repeat;
      background-color: $grey_mlight;
      min-height: 640px;
    }
    .header-text {
      font-size: 40px;
      padding-top: 40px;
    }
    .content-head {
      flex-direction: column;
      &__left {
        width: 100%;
        padding-top: 0px;
        padding-bottom: 30px;
      }
      &__right {
        width: 100%;
      }
    }
    .subheader-text {
      padding-bottom: 0px;
    }
  }
}

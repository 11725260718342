.classical-album-detail {
  padding-bottom: 200px;
  .album-hero {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0px;

    @media only screen and (max-width: $breakpoint_1024) {
      flex-direction: column;
    }

    &__left {
      display: flex;
      flex-direction: row;
      align-self: center;
      padding-right: 20px;

      @media only screen and (max-width: $breakpoint_518) {
        flex-direction: column;
      }

      .main-image {
        width: 300px;
        margin: auto 0px;
        align-self: center;
        object-fit: contain;

        &:hover {
          cursor: zoom-in;
        }
      }

      &__details {
        display: flex;
        flex-direction: column;
        padding-left: 28px;
        align-self: center;

        @media only screen and (max-width: $breakpoint_880) {
          padding-left: 10px;
          padding-right: 10px;
        }
        @media only screen and (max-width: $breakpoint_518) {
          text-align: center;
          padding-left: 0px;
          margin-top: 20px;
        }

        &__artistName {
          font-family: "Playfair Display", serif;
          font-weight: 600;
          font-size: 25px;
          line-height: 33px;
          text-transform: capitalize;
          padding-bottom: 10px;
        }

        &__grey {
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          line-height: 23px;
          color: #8f99aa;
        }

        &__playAll {
          display: flex;
          justify-content: space-evenly;
          background: #dd4e52;
          border: none;
          border-radius: 100px;
          height: 40px;
          width: 160px;
          padding: 0px 25px;
          align-items: center;
          color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
          outline: none;

          @media only screen and (max-width: $breakpoint_880) {
            width: 130px;
            margin-right: 0px;
          }
          @media only screen and (max-width: $breakpoint_518) {
            align-self: center;
          }
        }
      }
    }

    &__right {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      margin: auto 0px;
      align-self: center;
      padding-left: 20px;

      @media only screen and (max-width: $breakpoint_1024) {
        padding-top: 30px;
      }
    }
  }
}

.album-detail {
  .see-more-text {
    color: $blue;
  }
}
.classical-album-detail {
  .see-more-text {
    color: $red;
  }
}
.album-detail,
.classical-album-detail {
  
  .section-title {
    font-family: "Playfair Display", serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
  }
  
  .featured-artists {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    column-gap: 20px;
    max-height: 250px;
    overflow: hidden;
    transition: all 1.5s cubic-bezier(0, 1, 0, 1);

    &.expanded {
      max-height: 10000px;
      transition: all 1.5s cubic-bezier(1, 0, 1, 0);
    }
  }

  .see-more-text {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 76px;
    user-select: none;
  }
}
.about-album {
  max-height: 250px;
  transition: all 1.5s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0px 120px;

  &.full-width {
    padding: 0px;
    width: 100%;
  }
  @media only screen and (max-width: $breakpoint_658) {
    padding: 0px 0px;
  }

  div,
  font {
    font-family: "Roboto", sans-serif !important;
    font-size: 18px !important;
    line-height: 32px !important;
    color: $black !important;
  }

  p {
    margin-bottom: 20px;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 42px;
    margin: 15px 0;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    font-weight: bold;
  }

  blockquote {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 40px;
    margin-right: 40px;
  }

  &.expanded {
    max-height: 10000px;
    transition: all 1.5s cubic-bezier(1, 0, 1, 0);
  }

  &__content {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;

    &.with-columns {
      column-count: 2;
      column-gap: 50px;
      column-width: 500px;
    }
  }

  &__read-more {
    background: $white;
    border: solid 1px;
    border-color: $red;
    border-radius: 100px;
    padding: 11px;
    height: 40px;
    width: 160px;
    text-align: center;
    color: $red;
    cursor: pointer;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    margin: 30px 0px 0px 120px;
    user-select: none;

    &.full-width {
      margin: 30px 0px 76px 0px;
    }

    @media only screen and (max-width: $breakpoint_518) {
      margin: 30px 0px 76px 0px;
    }

    &:hover {
      background: linear-gradient(0deg, rgba(20, 17, 64, 0.2), rgba(20, 17, 64, 0.2)), $white;
      background: $red;
      color: $white;
    }
  }
}

.classical-album-detail,
.album-detail {
  &.common {
    .artists-list-to-truncate {
      max-height: 150px;
      @media only screen and (max-width: $breakpoint_518) {
        max-height: 120px;
      }
      overflow: hidden;
      transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

      &.open {
        max-height: 100rem;
        transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);

        .text {
          animation: truncate-open 0.1s linear 0s forwards;
          @media only screen and (max-width: $breakpoint_518) {
            animation: truncate-open-mobile 0.1s linear 0s forwards;
          }
        }
      }

      .text {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        line-height: 23px;
        color: inherit;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 12px 0;
        animation: truncate-close 0.1s linear 0.1s forwards;
        @media only screen and (max-width: $breakpoint_518) {
          animation: truncate-close-mobile 0.1s linear 0.1s forwards;
        }
        > a {
          color: inherit;
        }
      }
    }
  }
}

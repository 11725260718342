@import "./constants.scss";

.podcasts {
  padding-bottom: 50px;

  .slider-container {
    max-width: 1120px;
    margin: 0 auto;
    font-weight: normal;
    padding: 0px 16px 0px 16px;

    .header-nav-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h2 {
        font-family: "Playfair Display";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        padding: 57px 0px 24px 0px;
      }
    }

    .slider-component {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      padding: 12px;

      .contents-wrapper {
        display: flex;
        text-decoration: none;
        color: inherit;

        &.chapter {
          flex-direction: row;

          @media only screen and (max-width: $breakpoint_880) {
            flex-direction: column;
          }
        }

        &.podcast {
          flex-direction: column;
        }

        .image-wrapper {
          &.chapter {
            img {
              width: 160px;
              height: 160px;
              padding: 16px;

              @media only screen and (max-width: $breakpoint_880) {
                width: 250px;
                height: 250px;
              }

              @media only screen and (max-width: $breakpoint_658) {
                width: 100%;
                height: 100%;
              }
            }
          }

          &.podcast {
            img {
              width: 224px;
              height: 224px;

              @media only screen and (max-width: $breakpoint_880) {
                width: 250px;
                height: 250px;
                padding: 16px;
              }

              @media only screen and (max-width: $breakpoint_658) {
                width: 100%;
                height: 100%;
                padding: 16px;
              }
            }
          }

          img {
            object-fit: cover;
          }
        }

        .description-wrapper {
          padding-top: 16px;

          @media only screen and (max-width: $breakpoint_880) {
            padding: 0px 0px 8px 16px;
            width: 250px;
          }

          @media only screen and (max-width: $breakpoint_658) {
            width: 100%;
          }

          &.podcast {
            width: 224px;
          }

          .date {
            font-size: 14px;
            line-height: 135%;
            text-transform: uppercase;
            padding-bottom: 8px;
          }

          .title {
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
          }

          .parent-podcast {
            font-size: 16px;
            line-height: 24px;

            @media only screen and (max-width: $breakpoint_658) {
              font-size: 18px;
            }
          }

          .author {
            font-size: 16px;
            line-height: 24px;
            padding: 6px 16px 8px 0px;
          }
        }
      }
    }
  }

  .slide-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 57px;

    img {
      cursor: pointer;
      height: 46px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@import "./constants.scss";

.radios-detail {
  .header-radios {
    .content {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
    &__left {
      word-wrap: break-word;
    }
    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &.square {
        img {
          max-width: 400px;
        }
      }
      img {
        display: inline-block;
        width: 576px;
        height: 400px;
        object-fit: cover;
        position: relative;
        z-index: 2;
      }
    }
    .label-category-name {
      font-size: 16px;
      line-height: 28px;
      color: $red;
      margin-bottom: 25px;
      margin-top: 10px;
    }

    .category-jazz {
      margin-bottom: 40px;
      color: $blue;
    }

    .category-name {
      max-width: 384px;
      font-family: "Playfair Display", serif;
      font-weight: 600;
      font-style: normal;
      font-size: 42px;
      line-height: normal;
      color: $black;
      margin-bottom: 25px;
    }
    .desc-category {
      color: $grey_light;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 15px;
      max-width: 320px;
    }
    .duration-category {
      font-size: 14px;
      line-height: 20px;
      color: $grey_light;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      img {
        margin-right: 5px;
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 30px;
      .button-red {
        display: flex;
        min-width: 160px;
        height: 40px;
        justify-content: center;
        align-items: center;
        margin: 0 12px 0 0;
      }
      .button-red-border {
        display: flex;
        min-width: 160px;
        height: 40px;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        border: 1px solid $red;
        border-radius: 100px;
        color: $red;
        cursor: pointer;
      }
      img {
        margin-right: 10px;
      }
    }
  }
  .list-of-songs {
    padding: 64px 0;
    li {
      padding: 16px 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: $black;
      border-radius: 4px;
      &.active {
        .track-title {
          color: $red;
        }
      }
      &:hover {
        background: rgba(221, 78, 82, 0.1);
        .index {
          span {
            display: none;
          }
          .icon-play-circle-red {
            display: block;
          }
        }
      }
      .icon-play-circle-red {
        width: 40px;
        height: 40px;
        display: none;
        cursor: pointer;
      }
    }
    &__left {
      cursor: pointer;
    }
    &__left,
    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__right {
      color: $grey_light;
      line-height: 23px;
      padding-left: 50px;
    }
    .index {
      line-height: 23px;
      color: $grey_light;
      margin-right: 10px;
      min-width: 40px;
    }
    .info-songs {
      display: flex;
      flex-direction: column;
      min-height: 50px;
      justify-content: center;
      .row {
        display: flex;
        flex-direction: row;
      }
    }
    .composer {
      font-weight: bold;
      line-height: 23px;
      margin-right: 8px;
    }
    .track-title {
      line-height: 23px;
    }
    .performances {
      line-height: 23px;
      color: $grey_light;
      margin-top: 4px;
    }
    .icon-time {
      margin-right: 5px;
    }
    .icon-more {
      margin-left: 18px;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }

  .also-like {
    padding: 64px 0;
    .header {
      font-family: "Playfair Display", serif;
      font-weight: 600;
      font-style: normal;
      font-size: 28px;
      line-height: normal;
      margin-bottom: 24px;
    }
    &__boxes {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__box {
      width: 31.5%;
      background: $white;
      padding: 16px;
      position: relative;
      cursor: pointer;
      color: $black;
      &:hover {
        box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
        text-decoration: none;
      }
      .radio-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      .icon-play {
        position: absolute;
        width: 48px;
        height: 48px;
        right: 24px;
        top: 160px;
      }
      .title {
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;
        padding: 18px 0 2px 0;
      }
    }
  }

  .circle-embed-wrapper {
    display: flex;
    justify-content: center;
    height: 700px;
  }

  .error {
    text-align: center;
    font-size: 16px;
    margin-top: 50px;
  }
}

.adl-buttons {
  justify-content: space-between;
}
// Jazz
.radios-detail.jazz {
  .header-radios {
    .buttons {
      .button-red {
        background: $blue;
      }
    }
  }
  .also-like__box {
    width: 25%;
    background: transparent;
    text-align: center;
    .icon-play {
      display: none;
    }
    .title {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: $breakpoint_1200) {
  .radios-detail {
    .header-radios {
      &__left {
        width: 50%;
        padding-right: 30px;
      }
      &__right {
        img {
          max-width: 100%;
          width: auto;
          height: auto;
        }
      }
    }
  }
}
@media only screen and (min-width: $breakpoint_1024) {
  .adl-buttons {
    padding-right: 30px;
  }
}

@media only screen and (max-width: $breakpoint_1024) {
  .radios-detail {
    .header-radios {
      .category-name {
        font-size: 40px;
      }
    }
    .also-like {
      &__box {
        width: 48%;
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .radios-detail {
    .header-radios {
      &__left {
        width: 53%;
      }
      &__right {
        justify-content: center;
      }
      .buttons {
        justify-content: center;
        .button-red,
        .button-red-border {
          min-width: 0;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
    .list-of-songs {
      padding: 32px 0;
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .full-button-width {
    width: 100%;
  }

  .radios-detail {
    .header-radios {
      padding: 32px 0;
      .content {
        flex-direction: column;
      }
      .label-category-name {
        margin-bottom: 15px;
        margin-top: 0px;
      }
      .category-name {
        font-size: 32px;
        max-width: 100%;
        margin-bottom: 15px;
      }
      .desc-category {
        max-width: 100%;
        margin-bottom: 10px;
      }
      .buttons {
        margin-bottom: 32px;
      }
      &__left {
        width: 100%;
        padding-right: 0px;
      }
      &__right {
        width: 100%;
        img {
          width: 288px;
          height: 200px;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .radios-detail {
    .list-of-songs {
      padding: 32px 0;
      .time-text,
      .icon-time {
        display: none;
      }
      &__right {
        padding-left: 5px;
      }
    }
    .also-like {
      padding: 32px 0;
      &__box {
        width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

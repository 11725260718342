@import "./constants.scss";

.why-choose-vialma {
  padding-top: 120px;
  background: $grey_mlight;
  .header-choose {
    text-align: center;
    padding-bottom: 70px;
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    font-size: 42px;
    text-align: center;
  }
  .header-choose-small {
    font-size: 32px;
    line-height: 38px;
  }
  .content-choose {
    display: flex;
    flex-direction: row;
    &__left {
      width: 57%;
      margin-right: 3%;
      img {
        max-width: 100%;
      }
    }
    &__right {
      width: 40%;
    }
  }
  .list-choose {
    padding-top: 135px;
    &__icon {
      position: absolute;
      width: 48px;
      height: 48px;
      top: -7px;
      left: 0;
    }
    li {
      margin-bottom: 45px;
      padding-bottom: 45px;
      border-bottom: 1px solid $red;
      position: relative;
      padding-left: 64px;
      &:last-child {
        border: none;
      }
    }
    .head {
      padding-bottom: 20px;
      font-family: 'Playfair Display', serif;
      font-weight: 600;
      font-size: 22px;
    }
    .desc {
      line-height: 28px;
    }
  }
  .try-box {
    background: $red;
    min-height: 120px;
    margin-top: 110px;
    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &__left {
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          margin-right: 56px;
          width: 214px;
          height: 120px;
        }
        span {
          font-size: 32px;
          color: $white;
        }
      }
      .button-white {
        width: 288px;
        margin: 0;
      }
    }
  }
}
// Jazz
.jazz-page {
  .why-choose-vialma {
    .list-choose {
      li {
        border-color: $blue;
      }
    }
    .try-box {
      background: $blue;
      .content {
        .button-white {
          background: $white;
          color: $blue;
          &:hover {
            background: $blue;
            color: $white;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_1200) {
  .why-choose-vialma {
    .try-box {
      .content {
        &__left {
          span {
            font-size: 28px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_1024) {
  .why-choose-vialma {
    .list-choose {
      .head {
        font-size: 22px;
      }
      .desc {
        font-size: 14px;
        line-height: 23px;
      }
    }
    .try-box {
      .content {
        &__left {
          img {
            margin-right: 25px;
          }
          span {
            font-size: 24px;
          }
        }
        .button-white {
          width: 220px;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .why-choose-vialma {
    .list-choose {
      padding-top: 100px;
      li {
        margin-bottom: 25px;
        padding-bottom: 25px;
      }
      .head {
        padding-bottom: 12px;
      }
    }
    .try-box {
      display: flex;
      align-items: center;
      .content {
        &__left {
          img {
            display: none;
          }
        }
        .button-white {
          margin-left: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .why-choose-vialma {
    padding-top: 50px;
    .header-choose {
      padding-bottom: 50px;
      font-size: 32px;
    }
    .content-choose {
      flex-direction: column;
      &__left {
        width: 100%;
        img {
          margin: 0 auto;
        }
      }
      &__right {
        width: 100%;
      }
    }
    .list-choose {
      padding-top: 40px;
      li {
        padding-left: 0px;
      }
      &__icon {
        position: static;
        margin-bottom: 20px;
        margin-left: -3px;
      }
      .head {
        font-size: 24px;
      }
      .desc {
        font-size: 16px;
        line-height: 28px;
      }
    }
    .try-box {
      padding: 30px 0;
      margin-top: 0;
      .content {
        flex-direction: column;
        .button-white {
          margin-left: 0px;
          margin-top: 25px;
        }
      }
    }
  }
}

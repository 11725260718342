.composers {
  .content-composers {
    position: relative;
    min-height: 330px;
  }
  .composers-title {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 42px;
    color: $white;
  }
  .composers-desc {
    line-height: 28px;
    color: $white;
    max-width: 250px;
    margin-top: 20px;
  }
  .no-result {
    max-width: 410px;
    margin: 0 auto;
    text-align: center;
    line-height: 28px;
    img {
      width: 74px;
      height: 66px;
      display: block;
      margin: 64px auto 16px auto;
    }
  }
  .space-filters {
    height: 64px;
  }
  &__filters {
    box-shadow: inset 0px -1px 0px #dcdfe4;
    background: $white;
    height: 64px;
    &.fixed {
      position: fixed;
      z-index: 2;
      top: 68px;
      left: 0;
      right: 0;
      &.fixed-free {
        top: 116px;
      }
    }
    .content {
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      &__left,
      &__right {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &__search-box {
        width: 100%;
      }
      .input-search {
        width: 100%;
        height: 29px;
        border: none;
        font-size: 16px;
        line-height: 28px;
        color: $black;
        margin: 0;
        padding: 0;
      }
      .icon-enter {
        cursor: pointer;
      }
      .icon-close-search {
        margin-left: 40px;
        cursor: pointer;
      }
      .filter {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        height: 64px;
        .filter-link {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          &.active {
            color: $red;
          }
        }
        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
    }
  }
  .header-filter {
    display: none;
  }
  .sort-menu,
  .box-filter-menu {
    min-width: 350px;
    background: $white;
    border: 1px solid $grey_vlight;
    box-shadow: 0px 4px 6px $grey_vlight;
    padding: 10px 16px;
    position: absolute;
    z-index: 2;
    top: 63px;
    display: none;
    &.active {
      display: block;
    }
    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      cursor: pointer;
    }
  }
  .box-filter-menu {
    padding: 0;
    margin: 0;
  }
  .filter-menu {
    padding: 0;
    max-height: 270px;
    overflow: auto;
    li {
      display: block;
      padding: 22px 0;
      margin: 0 16px;
      font-weight: bold;
      border-bottom: 1px solid $grey_vlight;
      &:last-child {
        border: none;
      }
      .filter-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        span {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    ul {
      margin: 10px 0 0 0;
      padding: 0;
      li {
        margin: 0;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: none;
        font-weight: normal;
        &:last-child {
          padding-bottom: 0;
        }
        &.clear-filter {
          color: $grey_light;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
  .filters-menu {
    display: none;
    &.active {
      display: block;
    }
  }
  .filter-buttons {
    border-top: 1px solid $grey_vlight;
    background: $white;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .clear-filters {
      color: $grey_light;
      font-weight: bold;
      cursor: pointer;
    }
    .button-red {
      margin: 0;
      height: 40px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 150px;
      padding: 0 15px;
    }
  }
  .amount-filters {
    background: $grey_vlight;
    border-radius: 8px;
    font-size: 14px;
    font-weight: normal;
    margin-right: 20px;
    padding: 5px 8px;
    &.all {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .mask-composers {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    opacity: 0.8;
  }
  .search-result {
    background: $grey_mlight;
    height: 93px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.fixed {
      position: fixed;
      z-index: 1;
      top: 132px;
      width: 100%;
      &.fixed-free {
        top: 180px;
      }
    }
  }
  .result-search {
    font-size: 20px;
    line-height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 8px;
    }
  }
  .search-result-text {
    line-height: 28px;
    color: $grey_light;
  }
  .clear-search {
    cursor: pointer;
  }
  .feature-composers {
    margin-top: 48px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0% 3%;

    @media only screen and (max-width: $breakpoint_880) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: $breakpoint_518) {
      grid-template-columns: 1fr;
    }

    a {
      background: $white;
      border-radius: 8px;
      position: relative;
      border: 1px solid $grey_vlight;
      padding: 16px;
      display: block;
      text-decoration: none;
      &:hover {
        box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
        .feature-composer-info {
          &__photo {
            img {
              border-color: $red;
            }
          }
        }
      }
    }
    .label {
      border-radius: 0px 0px 4px 4px;
      background: $red;
      position: absolute;
      top: 0;
      left: 16px;
      padding: 2px 8px;
      font-size: 14px;
      line-height: 20px;
      color: $white;
    }
    .feature-composer-info {
      display: flex;
      flex-direction: row;
      padding: 20px 0 12px 0;
      border-bottom: 1px solid $grey_vlight;
      &__photo {
        img {
          min-width: 72px;
          min-height: 72px;
          max-width: 72px;
          max-height: 72px;
          border-radius: 50%;
          border: 3px solid $grey_vlight;
          margin-right: 16px;
        }
      }
    }
    .comment-composer {
      font-family: "Roboto", sans-serif !important;
      font-size: 16px !important;
      color: $black !important;
      line-height: 23px !important;
      padding-top: 12px;
      font {
        font-family: "Roboto", sans-serif !important;
        font-size: 16px !important;
        color: $black !important;
        line-height: 23px !important;
      }
    }
  }
  .composers-period-container {
    display: flex;
    flex-direction: row;
    .composers-list {
      flex: 3;
    }
    @media only screen and (max-width: $breakpoint_658) {
      flex-direction: column;
      .composers-list {
        grid-template-columns: 1fr;
      }
    }
    .composers-period-copy-container {
      position: relative;
      margin: 48px 0;
      flex: 2;
      line-height: 30px;
      padding-right: 10px;
    }
  }
  .composers-list {
    margin: 48px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0% 3%;

    &.two-per-row {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: $breakpoint_880) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: $breakpoint_518) {
      grid-template-columns: 1fr;
    }

    &.fixed {
      padding-top: 93px;
    }
    li {
      border-radius: 8px;
      margin-bottom: 24px;
      padding: 15px 20px;
      text-align: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      @extend .bordered;
      > a {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: $black;
        text-decoration: none;
      }
    }
    .composer-initials {
      min-width: 66px;
      min-height: 66px;
      max-width: 66px;
      max-height: 66px;
      border-radius: 50%;
      margin-right: 16px;
      border: 3px solid $grey_vlight;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $grey_vlight;
      font-size: 32px;
      line-height: 56px;
      color: $grey_light;
    }
    img {
      min-width: 66px;
      min-height: 66px;
      max-width: 66px;
      max-height: 66px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 16px;
      border: 3px solid $grey_vlight;
    }
    span {
      margin-right: 5px;
    }
  }
  &__left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .composer-name {
    line-height: 23px;
    color: $black;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .composer-details {
    line-height: 23px;
    color: $grey_light;
  }
  .composer-epoque {
    color: $grey_light;
    margin-top: 4px;
    span {
      margin-right: 5px;
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .composers {
    .feature-composers {
      a {
        margin-bottom: 16px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .composers {
    .composers-period-container {
      margin: 16px 0;
    }
    .composers-list {
      margin: 16px 0;
      li {
        width: 100%;
      }
    }
    .feature-composers {
      display: none;
    }
    &__filters {
      .content {
        padding: 0;
        .icon-close-search {
          margin-left: 20px;
        }
        .filter {
          position: static;
          .close-filter {
            position: absolute;
            top: 50%;
            margin-top: -16px;
            right: 11px;
            width: 32px;
            height: 32px;
            cursor: pointer;
          }
          img {
            margin-right: 5px;
          }
        }
      }
      .content-sort {
        position: relative;
      }
    }
    .sort-menu {
      left: 4px;
      right: 4px;
    }
    .header-filter {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 18px;
      text-align: center;
      box-shadow: inset 0px -1px 0px #dcdfe4;
      position: relative;
      height: 52px;
    }
    .filter-buttons {
      height: 73px;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    .box-filter-menu {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $white;
      min-width: 0;
      border: none;
      box-shadow: none;
    }
    .filter-menu {
      max-height: 100%;
      height: calc(100% - 145px);
    }
  }
  .content__search-box {
    padding-left: 16px;
  }
  .icon-close-search {
    padding-right: 16px;
  }
  .is-webplayer {
    .composers {
      .filter-buttons {
        bottom: 66px;
      }
    }
  }
}

@media (hover) {
  .composers-list {
    li {
      &:hover {
        background: $grey_mlight;
        img,
        .composer-initials {
          border-color: $red;
        }
      }
    }

    &.jazz {
      li {
        &:hover {
          img,
          .composer-initials {
            border-color: $blue;
          }
        }
      }
    }
  }
}

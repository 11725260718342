.RecommendList {
  padding-bottom: 15px;

  .recommend-title-box {
    font-family: "Playfair Display", serif;
    line-height: 25px;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .recommend-container {
    @extend .bordered;
    border-radius: $card_border_radius;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 10px;
    justify-content: space-between;
    .iconHeart {
      margin: 0;
    }
    &.jazz {
      &:hover {
        background: rgba(72, 102, 224, 0.1);
      }
    }
    &:hover {
      background: rgba(221, 78, 82, 0.1);
    }

    .recommend-child-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .track-detail {
        .artist {
          cursor: pointer;
          margin-top: 5px;
        }
      }
    }
  }

  .icon {
    padding-right: 11px;
    cursor: pointer;
    min-width: 50px;
  }

  .person-image {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 11px;
  }

  .name {
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
  }

  .work-name {
    font-weight: bold;
    line-height: 23px;
    text-transform: uppercase;
  }
  .content-works {
    max-width: 740px;
    min-height: 100vh;
    padding-bottom: 50px;
    .year {
      color: $grey_light;
      line-height: 23px;
    }
  }

  .arrow {
    margin-left: auto;
  }
}

.jazz {
  .RecommendList {
    .recommend-container {
      &:hover {
        background: rgba(72, 102, 224, 0.1);
      }
    }
  }
}

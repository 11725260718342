.jazz-page {
  .landing-page__head {
    background: url(../assets/images/mainpage_jazz.jpg) top center no-repeat;
    background-color: #12111f;
    min-height: 554px;
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .jazz-page {
    .landing-page__head {
      background: url(../assets/images/mainpage_jazz.jpg) top left
        no-repeat;
      background-color: #12111f;
      min-height: 640px;
    }
  }
}

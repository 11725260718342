@import "./constants.scss";

.default-page-section-container {
  display: block;
  position: relative;
  width: 100%;

  .divider-component__top {
    border-color: $black;
  }

  .header-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $size_16;

    &-left,
    &-right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    &-left {
      .title {
        font-family: $playfair;
        font-weight: 600;
        font-style: normal;
        font-size: 28px;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        @media screen and (max-width: $breakpoint_768) {
          flex-direction: column;
          align-items: flex-start;
        }
        .title-suffix {
          @media screen and (max-width: $breakpoint_768) {
              margin-left: 0;
              margin-top: 10px;
            
          }
          margin-left: 15px;
        }
      }
      .sub-title {
        font-family: $roboto;
        font-size: $size_18;
        color: #605f74;
        &.tiny {
          font-size: 12px;
        }
      }
    }

    &-right {
      justify-content: center;
      a {
        color: $red;
        &:visited {
          color: $red;
        }
      }
      &.jazz {
        a {
          color: $blue;
          &:visited {
            color: $blue;
          }
        }
      }
    }
    &-left,
    &-right,
    .see-more-text {
      font-family: "Roboto";
    }

    .see-more-text {
      background: none;
      border: none;
      color: $red;
      cursor: pointer;
      outline: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      user-select: none;
      &.jazz {
        color: $blue;
      }
    }
  }

  .body-section {
    &.default-grid {
      display: grid;
      column-gap: 20px;
      overflow: hidden;
      transition: all 1.5s cubic-bezier(0, 1, 0, 1);
    }
    &.default-stack {
      display: flex;
      flex-direction: column;
    }
  }
}

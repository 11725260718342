.welcome-banner {
  .banner-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0px auto;
    max-width: 1120px;
    @media only screen and (max-width: $breakpoint_880) {
      flex-direction: column-reverse;
    }
  }
  .welcome-play-wrapper {
    position: relative;
    padding: 5px;
    padding-right: 10px;
    margin-top: 15px;
    background-color: $red_light;
    transition: all 200ms;
    border-radius: 70px;
    &.jazz {
      background-color: $blue_light;
    }
    .interactive-play-icon {
      position: relative;
    }
    cursor: pointer;
    @extend .shadow;
    @extend .hover-shadow;
  }

  &.jazz {
    .left-section {
      a,
      a:visited {
        color: $blue;
      }
    }
  }
  .left-section {
    width: 40%;
    margin: auto 20px;

    transition: max-height 2.5s;
    transition-timing-function: cubic-bezier(1, 1, 0, 1);

    .subheader {
      font-size: 16px;
      font-family: $roboto;
      color: #605f74;
      font-weight: 500;
      line-height: 24px;
      padding-bottom: 7px;
    }

    .text-header {
      font-family: $playfair;
      font-size: 32px;
      font-weight: 700;
      line-height: 38px;
      padding-bottom: 25px;
    }

    height: auto;
    &.expand {
      max-height: 800px;
    }
    .recommend-copy,
    .text-header,
    .description {
      transition: opacity 1.75s ease-in-out;
      opacity: 1;
      z-index: 2;
      position: relative;
      &.invisible {
        opacity: 0;
      }
    }
    a,
    a:visited {
      color: $red;
    }
    .description {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #605f74;
      padding-bottom: 12px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .interactive-play-icon,
      img {
        opacity: 1;
      }
      .interactive-play-icon,
      img {
        margin-right: 10px;
      }
    }

    @media only screen and (max-width: $breakpoint_880) {
      width: 80%;

      .subheader {
        padding-top: 15px;
      }
    }
  }

  .right-section {
    a {
      .picture {
        height: 357px;
        width: 100%;
        object-fit: cover;
        mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 15%);
        -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 15%);
      }
    }

    @media only screen and (max-width: $breakpoint_880) {
      width: 100%;

      a {
        .picture {
          width: 100%;
          max-height: 152px;
          mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 30%);
          -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 30%);
        }
      }
    }
  }
}

.play-button {
  display: flex;
  font-family: "Roboto", sans-serif;
  flex-direction: row;
  color: #141140;
  font-weight: 700;
  font-size: 16px;
  text-decoration: underline;
  align-items: center;
  margin-top: 12px;
  background: none;
  border: none;
  padding: 0px;
  outline: none;
  cursor: pointer;

  & img {
    margin-right: 10px;
  }
}

.center-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.link-container {
  display: flex;
  flex-direction: row;
}

.box-day {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  padding: 22px;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation: fadeIn 1s ease both;
  animation-iteration-count: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  .img-day {
    max-width: 172px;
    max-height: 100px;
    margin-right: 22px;
  }
  .title {
    line-height: 28px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .link a {
    display: flex;
    flex-direction: row;
    color: $black;
    font-weight: bold;
    text-decoration: underline;
    align-items: center;
    margin-top: 12px;
    img {
      margin-right: 10px;
    }
  }
}
.loading-box {
  min-width: 300px;
}
.star-box-day-left {
  position: absolute;
  top: 20px;
  left: -75px;
}
.star-box-day-right {
  position: absolute;
  top: -14px;
  right: -60px;
}

.adjust-height {
  height: 20px;
}

@media only screen and (max-width: $breakpoint_658) {
  .welcome-banner {
    overflow: hidden;
    width: 100%;
    min-height: 170px;
    .content {
      min-height: 170px;
    }
    &__left {
      display: none;
    }
    &__right {
      width: 100%;
    }
  }
  .box-day {
    padding: 15px;
    .img-day {
      max-height: 90px;
    }
  }
}

.registration {
  .registration-container {
    background: $grey_mlight;
    display: flex;
    align-items: center;
    padding: 40px 0;
  }
  .box-registration {
    margin: 0 auto;
    background: $white;
    max-width: 896px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__left {
      img {
        max-height: 100%;
        max-width: 400px;
      }
    }
    .free-trial-box {
      padding: 0 48px;
      width: 100%;
      .header-trial {
        font-size: 32px;
        line-height: 45px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_1024) {
  .registration {
    .box-registration {
      .free-trial-box {
        padding: 20px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .registration {
    .box-registration {
      .free-trial-box {
        .header-trial {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .registration {
    .content {
      margin: 0;
    }
    .registration-container {
      background: $white;
      display: flex;
      align-items: center;
      padding: 0;
    }
    .box-registration {
      box-shadow: none;
      &__left {
        display: none;
      }
      .free-trial-box {
        padding: 24px 0 48px 0;
        .points {
          flex-direction: column;
        }
        .header-trial {
          font-size: 32px;
          line-height: 45px;
        }
      }
    }
  }
}

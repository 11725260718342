.DailyPlaylists {
  margin-bottom: 80px;
  width: 100%;

  &__SectionTitle {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    margin-bottom: 48px;

    @media only screen and (max-width: $breakpoint_768) {
      margin-bottom: 20px;
    }
  }

  &__Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @media only screen and (max-width: $breakpoint_1024) {
      justify-content: space-around;
    }

    @media only screen and (max-width: $breakpoint_768) {
      flex-direction: column;
    }

    a {
      padding: 16px;
      background-color: white;
      margin-bottom: 16px;
      cursor: pointer;
      position: relative;
      color: $black;
      display: block;
      &:hover {
        text-decoration: none;
        box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
      }

      @media only screen and (max-width: $breakpoint_768) {
        width: 100%;
      }
    }

    img {
      margin: auto;
      object-fit: cover;
      max-width: 100%;
      height: 200px;

      @media only screen and (max-width: $breakpoint_768) {
        max-width: unset;
        width: 100%;
      }
    }

    &__playIcon {
      position: absolute;
      right: 24px;
      top: 160px;

      img {
        width: 48px;
        height: 48px;
      }
    }

    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin: 16px 0 0 0;
    }
  }
}

.interpretation {
  .hero-section {
    width: 100%;
    background: #f4f4f4;

    .section-wrapper {
      display: flex;
      flex-direction: row;
      max-width: 1120px;
      margin: 0px auto;
      padding: 28px 0px;

      img {
        padding: 0px 24px;

        @media only screen and (max-width: $breakpoint_768) {
          padding-left: 16px;
        }
      }

      .recording-info {
        min-height: 150px;
        display: flex;
        flex-direction: column;
        padding-right: 16px;
        justify-content: space-evenly;

        .title {
          font-family: 'Playfair Display';
          font-weight: 600;
          font-size: 22px;
          line-height: 29px;

          @media only screen and (max-width: $breakpoint_768) {
            padding: 10px 0px;
          }
        }
        .composer {
          padding: 10px 0px;
          a {
            font-family: "Roboto", sans-serif;
            line-height: 23px;
            font-size: 16px;
            color: #ebae71;
          }
        }

        .bottom-part {
          font-family: "Roboto", sans-serif;
          display: flex;

          @media only screen and (max-width: $breakpoint_768) {
            flex-direction: column;
          }

          .year, 
          .record-label {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            min-height: 45px;
            padding: 5px 0px;

            padding-right: 56px;
            .text {
              font-size: 14px;
              color: #8f99aa;
              line-height: 125%;
              padding: 2px 0px;
            }
            .number {
              font-size: 16px;
              line-height: 23px;
              padding: 2px 0px;
            }
          }

          .artists {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            min-height: 45px;
            padding: 5px 0px;

            .text {
              font-size: 14px;
              color: #8f99aa;
              line-height: 125%;
              padding: 2px 0px;
            }
            .list {
              span {
                color: #ebae71;
              }
              a {
                font-size: 16px;
                color: #ebae71;
                line-height: 23px;
                padding: 2px 0px;
              }
            }
          }
        }
      }
    }
  }

  .tracks-section {
    display: flex;
    flex-direction: row;
    max-width: 1120px;
    margin: 0px auto;
    padding-top: 40px;

    .track-left-placeholder {
      width: 120px;

      @media only screen and (max-width: $breakpoint_768) {
        display: none;
      }
    }

    .track-list {
      padding-left: 16px;
      width: 100%;

      .recording-track {
        height: 56px;
        display: flex;
        margin: 0px auto;
        padding: 28px 0px;
        justify-content: space-between;

        .track-left {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          img {
            cursor: pointer;
          }

          .track-name { 
            padding-left: 20px;
          }
        }
        
        .track-right {
          display: flex;
          align-items: center;
          padding-right: 15px;
        }
      }
    }
  }

  .loading {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.about {
  .header-about {
    background: $red;
    color: $white;
    font-family: "Playfair Display", serif;
    font-size: 46px;
    line-height: 58px;
    text-align: center;
    padding: 20px 0;
    &.jazz {
      background: $blue;
    }
  }
  .section-about {
    padding: 78px 0;
    &.grey {
      background: $grey_mlight;
    }
    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .screenshot-1 {
      width: 640px;
      height: 368px;

      &.jazz {
        height: 458px;
      }
    }
    .screenshot-2 {
      max-width: 100%;
    }
  }
  .head-txt {
    font-family: "Playfair Display", serif;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 24px;
  }
  .desc {
    font-size: 16px;
    line-height: 28px;
    max-width: 608px;
    margin-bottom: 46px;
  }
  .projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    &__box {
      border: 1px solid $black;
      border-radius: 4px;
      padding: 0 16px;
      width: 31.5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-top: 10px;
        max-width: 100%;
      }
      .project-text {
        margin: 16px 0;
        text-align: center;
        line-height: 24px;
        a {
          color: $red;
          &:visited {
            color: $red;
          }
        }
      }
    }
  }
  .proud {
    display: flex;
    flex-direction: column-reverse;
    .header-txt {
      font-size: 32px;
      line-height: 38px;
    }
    .box-proud {
      padding: 80px 0;
    }
  }
  .why-choose-vialma {
    padding-bottom: 50px;
    .desc {
      margin-bottom: 0px;
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .about {
    .projects {
      &__box {
        width: 48%;
        margin-bottom: 28px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .about {
    .header-about {
      font-size: 32px;
      line-height: 38px;
    }
    .head-txt {
      font-size: 24px;
      line-height: 29px;
    }
    .section-about {
      padding: 50px 0;

      .screenshot-1 {
        width: 352px;
        height: 208px;

        &.jazz {
          width: 100%;
        }
      }
    }
    .projects {
      &__box {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
}

.album-detail {
  .info-album-header {
    background: $grey_mlight;
    padding: 40px 0;
    &__left {
      display: flex;
      flex-direction: row;
      width: 50%;

      .shareAllButton, .mc-heart-default {
        display: flex;
        @media only screen and (max-width: $breakpoint_768) {
          display: none;
        }
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-left: 20px;
      .shareAllButton, .mc-heart-default {
        display: none;
        @media only screen and (max-width: $breakpoint_768) {
          display: flex;
        }
      }
    }
    .content {
      display: flex;
      flex-direction: row;
    }
  }
  .image {
    min-width: 160px;
    min-height: 160px;
    max-width: 160px;
    max-height: 160px;
    background: #ccc;
    margin-right: 24px;
    img {
      max-width: 100%;
      object-fit: fill;
    }
  }
  .name {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 12px;
  }
  .amount {
    color: $grey_light;
  }
  .value-release {
    margin-bottom: 16px;
    line-height: 23px;
  }
  .label-release,
  .label-artist,
  .label-label {
    color: $grey_light;
    font-size: 14px;
    margin-bottom: 12px;
  }
  .label-label {
    margin-top: 12px;
  }
  .value-artist {
    color: $blue;
    span,
    a {
      line-height: 23px;
      color: $blue;
      &:after {
        content: ", ";
        color: $black;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
  .details {
    position: relative;
  }
  .btn-play-all {
    background: $blue;
    border-radius: 100px;
    min-width: 160px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: $white;
    cursor: pointer;
    img {
      margin-right: 10px;
    }
  }
  .play-all-mobile {
    display: none;
  }
  .list-of-songs {
    padding: 64px 0;
    > li {
      padding: 16px 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: $black;
      border-radius: 4px;
      &.active {
        .track-title {
          color: $blue;
        }
      }
      &:hover {
        background: rgba(72, 102, 224, 0.1);
        .index {
          span {
            display: none;
          }
          .icon-play-circle-red {
            display: block;
          }
        }
      }
      .icon-play-circle-red {
        width: 40px;
        height: 40px;
        display: none;
        cursor: pointer;
      }
    }
    &__left {
      cursor: pointer;
    }
    &__left,
    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__right {
      color: $grey_light;
      line-height: 23px;
      padding-left: 50px;
    }
    .index {
      line-height: 23px;
      color: $grey_light;
      margin-right: 10px;
      min-width: 40px;
    }
    .info-songs {
      display: flex;
      flex-direction: column;
      min-height: 50px;
      justify-content: center;

      .row {
        display: flex;
        flex-direction: row;
      }
    }
    .track-title {
      line-height: 23px;
    }
    .artists {
      line-height: 23px;
      color: $grey_light;
      margin-top: 4px;
      span {
        &:after {
          content: "•";
          margin: 0 5px;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
    .icon-time {
      margin-right: 5px;
    }
    .icon-more {
      margin-left: 18px;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .album-detail {
    .details {
      .btn-play-all {
        min-width: 0;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .album-detail {
    .list-of-songs {
      padding: 32px 0;
    }
    .info-album-header {
      .content {
        flex-direction: column;
        .image,
        .btn-play-all {
          display: none;
        }
        .play-all-mobile {
          display: flex;
          position: static;
          max-width: 160px;
          min-width: 160px;
        }
      }
      &__left,
      &__right {
        width: 100%;
      }
      &__right {
        padding-left: 0;
        padding-top: 24px;
      }
    }
  }
}

.artist-details {
  .header-artist {
    background: $black;
    padding: 40px 0;
    margin-bottom: 60px;
  }
  .info-artist {
    display: flex;
    flex-direction: row;
    align-items: center;
    .image-artist {
      img {
        max-width: 80px;
        max-height: 80px;
        min-width: 80px;
        min-height: 80px;
        border-radius: 50%;
        margin-right: 20px;
        object-fit: cover;
      }
      .initials {
        max-width: 80px;
        max-height: 80px;
        min-width: 80px;
        min-height: 80px;
        border-radius: 50%;
        background: #dcdfe4;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        color: #8f99aa;
      }
    }
  }
  .name-artist {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    color: $white;
  }
  .head-tracks {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    margin-bottom: 32px;
  }
  .list-of-songs {
    padding-bottom: 64px;
    > li {
      padding: 16px 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: $black;
      border-radius: 4px;
      min-height: 72px;
      &.active {
        .track-title {
          color: $blue;
        }
      }
      &:hover {
        background: rgba(72, 102, 224, 0.1);
        .index {
          span {
            display: none;
          }
          .icon-play-circle-red {
            display: block;
          }
        }
      }
      .icon-play-circle-red {
        width: 40px;
        height: 40px;
        display: none;
        cursor: pointer;
      }
    }
    &__left {
      cursor: pointer;
    }
    &__left,
    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__right {
      color: $grey_light;
      line-height: 23px;
      padding-left: 50px;
    }
    .index {
      line-height: 23px;
      color: $grey_light;
      margin-right: 10px;
      min-width: 40px;
    }
    .info-songs {
      display: flex;
      flex-direction: column;
      min-height: 50px;
      justify-content: center;
      .row {
        display: flex;
        flex-direction: row;
      }
    }
    .track-title {
      line-height: 23px;
    }
    .icon-time {
      margin-right: 5px;
    }
    .icon-more {
      margin-left: 18px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
}
.appears-on {
  background: $grey_mlight;
  padding-bottom: 62px;
  a {
    color: $black;
    text-decoration: none;
  }
  .header {
    padding: 62px 0;
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
  }

  .spacer {
    width: 40px;
  }

  .loading-icon {
    display: flex;
    justify-content: center;
  }

  .appears-list {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 40px;

    @media only screen and (max-width: $breakpoint_768) {
      justify-content: space-around;
    }

    li {
      width: 200px;
      height: 200px;
      position: relative;
      box-sizing: border-box;
      margin-bottom: 63px;
      margin-right: 50px;

      @media only screen and (max-width: $breakpoint_518) {
        width: 100%;
        height: 400px;
      }

      img {
        max-width: 100%;

        @media only screen and (max-width: $breakpoint_518) {
          width: 100%;
        }
      }
      .name {
        font-weight: bold;
        margin-top: 16px;
      }
    }
  }
}

@import './constants.scss';

.track-of-the-day-loading {
  width: 448px;
  min-height: 277px;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.track-of-the-day {
  width: 448px;
  font-size: 16px;
  .header {
    background: $red;
    height: 32px;
    padding-left: 18px;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
  }
  .info {
    background: $white;
    padding: 16px;
  }
  .header-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &__left {
      margin-right: 55px;
    }
  }
  .icon-play {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      position: relative;
      left: 5px;
    }
  }
  .name {
    font-weight: bold;
    color: $black;
  }
  .desc {
    margin-top: 10px;
    line-height: 23px;
    color: $black;
  }
  .details {
    background: $grey_mlight;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    .find-button {
      color: $red;
      display: inline-block;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .image-composer {
    margin-right: 16px;
    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      border: 2px solid $red;     
    }
  }
  .details-desc {
    color: $grey_light;
    line-height: 23px;
    padding-bottom: 8px;
  }
}

@media only screen and (max-width: $breakpoint_1024) {
  .track-of-the-day {
    width: 100%;
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .track-of-the-day {
    .details-desc {
      font-size: 14px;
      line-height: 20px;
    }
    .header-info {
      &__left {
        margin-right: 25px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .track-of-the-day {
    .details-desc {
      font-size: 16px;
      line-height: 23px;
    }
  }
  .track-of-the-day-loading {
    width: 100%;
  }
}
.chapter-details {
  .hero-section {
    background: $grey_mlight;
    padding: 34px 0;
    position: relative;
    margin-bottom: 64px;

    .content {
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      @media only screen and (max-width: $breakpoint_880) {
        flex-direction: column;
      }

      &__left {
        width: 40%;

        @media only screen and (max-width: $breakpoint_880) {
          width: 100%;
        }

        img {
          width: 192px;
          height: 192px;
          object-fit: cover;
          margin: auto;
          @media only screen and (max-width: $breakpoint_880) {
            width: 100%;
            height: 100%;
            max-width: 400px;
            padding: 20px;
          }
        }
      }

      &__right {
        width: 60%;

        @media only screen and (max-width: $breakpoint_880) {
          width: 100%;
        }

        .release-date {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 135%;
          padding-bottom: 10px;
        }

        .name {
          font-family: 'Playfair Display';
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 38px;
          display: flex;
          align-items: center;
          padding-bottom: 4px;
        }

        .podcast-name {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          display: flex;
          align-items: center;
          color: #ebae71;
          padding-bottom: 10px;
        }

        .duration {
          line-height: 125%;
          color: $grey_light;
          padding: 8px 0px 8px 0px;
          display: flex;
          flex-direction: row;

          img {
            width: 15px;
            height: 15px;
            margin: auto 5px auto 0px;
          }
        }

        .buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-top: 16px;
          .play-container {
            display: flex;

            .play {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .loading {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chapter-description {
    margin: 0px auto;
    max-width: 930px;
    padding-bottom: 40px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    line-height: 32px;
  }

  .playlist-title {
    margin: 0px auto;
    max-width: 930px;
    padding-bottom: 24px;
    font-family: 'Playfair Display';
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    display: flex;
    align-items: center;
  }
}

.composer-detail {
  &__header {
    background: $black;
    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 200px;
    }
  }
  &__image {
    margin-right: 24px;
    img {
      width: 96px;
      height: 96px;
      border-radius: 50%;
    }
  }
  .composer-name {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    color: $white;
    margin-bottom: 13px;
  }
  .composer-date-country,
  .composer-epoque {
    color: $grey_light;
    line-height: 28px;
  }
  .quotes {
    background: $grey_mlight;
    .content {
      min-height: 160px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 60px;
      .img-quote {
        width: 20px;
        height: 12px;
      }
    }
    .quote-text {
      line-height: 28px;
      margin: 0 40px;
      max-width: 570px;
      text-align: center;
      br {
        display: none;
      }
      div,
      font {
        font-family: "Roboto", sans-serif !important;
        font-size: 16px !important;
        color: $black !important;
        text-align: center;
      }
    }
    .arrow-left,
    .arrow-right {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
    .arrow-left {
      position: absolute;
      left: 5px;
    }
    .arrow-right {
      position: absolute;
      right: 5px;
    }
  }
  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px -1px 0px #dcdfe4;
    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 72px;
      margin: 0 32px;
      color: $grey_light;
      font-family: 'Playfair Display', serif;
      font-weight: 600;
      font-style: normal;
      font-size: 22px;
      position: relative;
      cursor: pointer;
      &.active {
        color: $black;
        &:before {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          height: 3px;
          width: 75%;
          background: $red;
        }
      }
    }
  }
  .discover-box {
    padding-top: 48px;
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;
    &__left {
      width: 50%;
      padding-right: 100px;
    }
    &__right {
      width: 50%;
    }
  }
  .title-box {
    line-height: 25px;
    font-size: 18px;
    font-weight: bold;
    padding-left: 8px;
    border-left: 6px solid $black;
    margin-bottom: 32px;
  }
  .bio {
    font-family: "Roboto", sans-serif !important;
    font-size: 16px !important;
    color: $black !important;
    line-height: 28px !important;
    div,
    font {
      font-family: "Roboto", sans-serif !important;
      font-size: 16px !important;
      color: $black !important;
      line-height: 28px !important;
    }
  }
  .list-of-works {
    li {
      background: $white;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:hover {
        background: rgba(221, 78, 82, 0.1);
      }
      img {
        cursor: pointer;
        margin: auto 16px;
      }
      .info-works {
        cursor: pointer;
      }

      .arrow {
        margin-left: auto;
      }
    }
    .work-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      color: inherit;
    }
  }
  .no-result {
    max-width: 410px;
    margin: 0 auto;
    text-align: center;
    line-height: 28px;
    img {
      width: 74px;
      height: 66px;
      display: block;
      margin: 64px auto 16px auto;
    }
  }
  .icon-play {
    min-width: 40px;
    min-height: 40px;
    margin-right: 10px;
    cursor: pointer;
  }
  .work-name {
    font-weight: bold;
    line-height: 23px;
  }
  .content-works {
    max-width: 740px;
    min-height: 100vh;
    padding-bottom: 50px;
    .year {
      color: $grey_light;
      line-height: 23px;
    }
  }
  .content__search-box {
    padding-left: 16px;
  }
  .search-container {
    height: 64px;
    margin: 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    max-width: 740px;

    &.only-button {
      justify-content: flex-end;
    }

    &__left,
    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__search-box {
      width: 100%;
    }

    .input-search {
      width: 100%;
      height: 29px;
      border: none;
      font-size: 16px;
      line-height: 28px;
      color: $black;
      margin: 0;
      padding: 0;
    }
    .icon-enter {
      cursor: pointer;
    }
    .icon-close-search {
      margin-left: 40px;
      margin-right: 10px;
      cursor: pointer;
    }
    .button-search {
      border: 1px solid $black;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 4px 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        margin-right: 8px;
        width: 25px;
        height: 25px;
      }
    }
  }
  .search-result {
    background: $grey_mlight;
    margin: 30px 0;
    height: 93px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .result-search {
    font-size: 20px;
    line-height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 8px;
    }
  }
  .search-result-text {
    line-height: 28px;
    color: $grey_light;
  }
  .clear-search {
    cursor: pointer;
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .composer-detail {
    .tabs {
      margin: 0 -16px;
      div {
        font-size: 18px;
        height: 48px;
      }
    }
    .discover-box {
      flex-direction: column;
      &__left {
        width: 100%;
        padding-right: 0;
      }
      &__right {
        width: 100%;
        margin-top: 30px;
      }
    }
    .icon-play {
      display: none;
    }
    .list-of-works {
      li {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .search-container {
      margin: 16px 0;
    }
    .search-result {
      margin: 16px -16px;
    }
  }
}

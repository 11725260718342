.artists {
  .space-filters {
    height: 64px;
  }
  .space-search-result {
    height: 93px;
  }
  .artists-title {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 42px;
    color: $white;
  }
  .artists-desc {
    line-height: 28px;
    color: $white;
    margin-top: 20px;
  }
  .featured-artists {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
    li {
      width: 32%;
      border: 1px solid $grey_vlight;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 42px;
      position: relative;
      cursor: pointer;
      .label {
        position: absolute;
        top: 0;
        left: 16px;
        border-radius: 0px 0px 4px 4px;
        background: $blue;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        font-size: 14px;
        color: $white;
      }
      .artist-image {
        width: 66px;
        height: 66px;
        border-radius: 50%;
        border: 3px solid #dcdfe4;
        margin-top: 32px;
        margin-bottom: 16px;
        object-fit: cover;
      }
      .initials {
        width: 66px;
        height: 66px;
        border-radius: 50%;
        border: 3px solid #dcdfe4;
        background: #dcdfe4;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 16px;
        font-size: 32px;
        color: #8f99aa;
      }
      .artist-name {
        font-weight: bold;
        text-align: center;
        line-height: 20px;
        margin-bottom: 18px;
      }
    }
  }
  .artists {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: 25%;
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 48px;
      cursor: pointer;
      .artist-image {
        width: 66px;
        height: 66px;
        border-radius: 50%;
        border: 3px solid #dcdfe4;
        margin-top: 32px;
        margin-bottom: 16px;
        object-fit: cover;
      }
      .initials {
        width: 66px;
        height: 66px;
        border-radius: 50%;
        border: 3px solid #dcdfe4;
        background: #dcdfe4;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 16px;
        font-size: 32px;
        color: #8f99aa;
      }
      .artist-name {
        font-weight: bold;
        text-align: center;
        line-height: 20px;
      }
    }
  }
  .search-result {
    background: $grey_mlight;
    height: 93px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.fixed {
      position: fixed;
      z-index: 1;
      top: 132px;
      width: 100%;
      &.fixed-free {
        top: 180px;
      }
    }
  }
  .result-search {
    font-size: 20px;
    line-height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 8px;
    }
  }
  .search-result-text {
    line-height: 28px;
    color: $grey_light;
  }
  .clear-search {
    cursor: pointer;
  }
  .no-result {
    max-width: 410px;
    margin: 0 auto;
    text-align: center;
    line-height: 28px;
    padding-bottom: 20px;
    img {
      width: 74px;
      height: 66px;
      display: block;
      margin: 64px auto 16px auto;
    }
  }
  .button-filter {
    img {
      margin-left: 10px;
    }
    .option {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        width: 100px;
      }
      img {
        margin-left: 20px;
      }
    }
  }

  .search-section {
    margin-top: 48px;
    &__head {
      .suffix-component {
        position: absolute;
        right: 0;
        top: 0px;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        color: #141140;

        span {
          color: #141140;
        }
      }
    }
  }
}
.artist {
  &__filters {
    background: $white;
    box-shadow: inset 0px -1px 0px #dcdfe4;
    background: $white;
    height: 64px;
    &.fixed {
      position: fixed;
      z-index: 2;
      top: 68px;
      left: 0;
      right: 0;
    }
    .content {
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      &.single-item {
        justify-content: flex-start;

        .filter {
          width: auto;
        }
      }
      &__left,
      &__right {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &__search-box {
        width: 100%;
      }
      .input-search {
        width: 100%;
        height: 29px;
        border: none;
        font-size: 16px;
        line-height: 28px;
        color: $black;
        margin: 0;
        padding: 0;
      }
      .icon-enter {
        cursor: pointer;
      }
      .icon-close-search {
        margin-left: 40px;
        cursor: pointer;
      }
    }
    .filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
    }
    .filter-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      &.active {
        color: $red;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
    .sort-menu,
    .box-filter-menu {
      min-width: 350px;
      background: $white;
      border: 1px solid $grey_vlight;
      padding: 10px 16px;
      position: absolute;
      z-index: 1002;
      top: 43px;
      display: none;
      &.active {
        display: block;
      }
      li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        cursor: pointer;
      }
      .header-filter {
        display: none;
        justify-content: center;
        align-items: center;
        padding: 18px;
        text-align: center;
        box-shadow: inset 0px -1px 0px #dcdfe4;
        position: relative;
        height: 52px;
        .close-filter {
          position: absolute;
          top: 50%;
          margin-top: -16px;
          right: 11px;
          width: 32px;
          height: 32px;
          cursor: pointer;
        }
      }
      .filters-menu {
        li {
          margin: 0;
          padding: 10px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border: none;
          font-weight: normal;
          color: $black;
          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
        }
      }
      .sort-menu {
        left: 4px;
        right: 4px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .artists {
    .featured-artists {
      li {
        width: 48%;
      }
    }
    .artists {
      li {
        width: 32%;
        margin-bottom: 16px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .artists {
    .artists {
      margin: 16px 0;
      li {
        width: 48%;
      }
    }
    .featured-artists {
      display: none;
    }
    .artist__filters {
      &.fixed.app-banner {
        top: 172px;
        width: 100%;
        padding: 0;
      }
      .content {
        padding: 0;
        width: 100%;
        .icon-close-search {
          margin-left: 20px;
        }
        .filter {
          position: static;
          .close-filter {
            position: absolute;
            top: 50%;
            margin-top: -16px;
            right: 11px;
            width: 32px;
            height: 32px;
            cursor: pointer;
          }
          img {
            margin-right: 5px;
          }
        }
      }
      .content-sort {
        position: relative;
      }
    }
    .sort-menu {
      left: 0px;
      right: 0px;
    }
    .header-filter {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 18px;
      text-align: center;
      box-shadow: inset 0px -1px 0px #dcdfe4;
      position: relative;
      height: 52px;
    }
    .filter-buttons {
      height: 73px;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    .box-filter-menu {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 20;
      background: $white;
      min-width: 0;
      border: none;
      box-shadow: none;
    }
    .filter-menu {
      max-height: 100%;
      height: calc(100% - 145px);
    }
  }
  .content__search-box {
    padding-left: 16px;
  }
  .icon-close-search {
    padding-right: 16px;
  }
  .is-webplayer {
    .composers {
      .filter-buttons {
        bottom: 66px;
      }
    }
  }
}


.works {
  &__content {
    display: flex;
    flex-direction: row;
    &__filters {
      span {
        padding: 10px;
        margin: 10px;
        border: 2px solid red;
        display: inline-block;
        background: red;
        color: #fff;
        cursor: pointer;
        &.activeFilter {
          background: #fff;
          color: red;
          cursor: default;
        }
      }
    }
  }
  .column {
    flex: 1;
  }
  .root-category {
    margin-top: 20px;
  }
  li {
    cursor: pointer;
    ul {
      display: none;
    }
    &.active {
      ul {
        display: block;
      }
    }
    li {
      margin-left: 20px;
    }
  }
  .works-composers {
    margin-top: 20px;
  }
}
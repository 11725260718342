.content-upgrade {
  background: $grey_mlight;
  .back {
    margin-bottom: 0;
  }
}
.header-upgrade {
  background: linear-gradient(120.91deg, #141140 38.41%, #dd4e52 100%);
  padding: 35px 0;
  text-align: center;
  color: $white;
  .title {
    font-family: "Playfair Display", serif;
    line-height: normal;
    font-size: 28px;
    margin-bottom: 5px;
  }
  .subtext {
    font-size: 24px;
    line-height: 42px;
  }
}
.upgrade-content {
  background: $grey_mlight;
  padding: 72px 0 102px 0;
  .link-benefits {
    display: none;
  }
}
.plans {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .box {
    width: 32%;
    background: $white;
    padding: 20px 16px;
    position: relative;
    .label-popular {
      position: absolute;
      top: 28px;
      right: 16px;
      border-radius: 2px;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      padding: 2px 6px;
      color: $green;
    }
    .checkbox-selected {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      margin-right: 17px;
      position: relative;
      left: 1px;
      &:before {
        content: "";
        position: absolute;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        top: 3px;
        left: 3px;
      }
    }
    &.brown {
      border-top: 4px solid $brown;
      .checkbox-selected {
        border: 1px solid $brown;
        &:before {
          background: $brown;
        }
      }
      .active {
        border: 2px solid $brown;
      }
      .btn-upgrade {
        background: $brown;
        &.disabled {
          background: $grey_vlight;
          pointer-events: none;
        }
      }
      .prices {
        color: $brown;
      }
    }
    &.green {
      border-top: 4px solid $green;
      position: relative;
      top: -24px;
      .checkbox-selected {
        border: 1px solid $green;
        &:before {
          background: $green;
        }
      }
      .active {
        border: 2px solid $green;
      }
      .btn-upgrade {
        background: $green;
        &.disabled {
          background: $grey_vlight;
          pointer-events: none;
        }
      }
      .prices {
        color: $green;
      }
    }
    &.red {
      border-top: 4px solid $red;
      .checkbox-selected {
        border: 1px solid $red;
        &:before {
          background: $red;
        }
      }
      .active {
        border: 2px solid $red;
      }
      .btn-upgrade {
        background: $red;
        &.disabled {
          background: $grey_vlight;
          pointer-events: none;
        }
      }
      .prices {
        color: $red;
      }
    }
    &.blue {
      border-top: 4px solid $blue;
      .checkbox-selected {
        border: 1px solid $blue;
        &:before {
          background: $blue;
        }
      }
      .active {
        border: 2px solid $blue;
      }
      .prices {
        color: $blue;
      }
      .btn-upgrade {
        background: $blue;
        &.disabled {
          background: $grey_vlight;
          pointer-events: none;
        }
      }
    }
    &:hover,
    &.disabled {
      box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
    }
    .title {
      font-family: "Playfair Display", serif;
      line-height: normal;
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 18px;
    }
    .option {
      border: 1px solid $border;
      padding: 5px 10px;
      font-size: 20px;
      line-height: 35px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 15px;
      position: relative;
      cursor: pointer;
      &.disabled {
        &:before {
          content: "";
          position: absolute;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          top: 17px;
          left: 17px;
          background: $border;
        }
      }
      .label-save {
        position: absolute;
        top: 13px;
        right: 10px;
        background: $grey_vlight;
        border-radius: 2px;
        font-size: 14px;
        line-height: 16px;
        padding: 2px 6px;
      }
      img {
        margin-right: 13px;
        width: 25px;
        height: 25px;
      }
      span {
        margin-left: 4px;
        font-size: 14px;
        line-height: 20px;
        color: $grey_light;
      }
    }
    .options {
      padding: 12px 0;
      min-height: 275px;
      img {
        width: 24px;
        height: auto;
      }
      li {
        line-height: 24px;
        font-size: 16px;
        color: $black;
        text-align: left;
        padding-left: 40px;
        position: relative;
        margin-bottom: 7px;
        img {
          position: absolute;
          top: -1px;
          left: 0px;
          display: block;
        }
      }
    }
    .prices {
      text-align: center;
      padding-bottom: 10px;
      font-size: 22px;
    }
    .btn-upgrade {
      display: flex;
      height: 48px;
      background: $border;
      color: $white;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold;
      border-radius: 100px;
      cursor: pointer;
      text-decoration: none;
      border: none;
      width: 100%;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
// Jazz
.jazz {
  .header-upgrade {
    background: linear-gradient(120.91deg, #141140 38.41%, #4866e0 100%);
  }
}

@media only screen and (max-width: $breakpoint_1024) {
  .upgrade-content {
    padding: 30px 0;
  }
  .plans {
    flex-wrap: wrap;
    .box {
      width: 48%;
      margin-bottom: 30px;
      &.red {
        top: 0;
      }
      .option {
        .label-save {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .header-upgrade {
    .subtext {
      font-size: 18px;
    }
  }
  .upgrade-content {
    padding: 16px 0;
    .link-benefits {
      display: block;
      color: $red;
      margin-bottom: 15px;
      cursor: pointer;
    }
  }
  .plans {
    flex-direction: column;
    .box {
      width: 100%;
      .options {
        min-height: 185px;
        height: 0px;
        overflow: hidden;
        min-height: 0px;
        padding: 0;
        &.show-benefits {
          display: block;
          height: auto;
          margin-bottom: 15px;
        }
      }
    }
  }
}

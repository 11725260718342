.ComposerRandomTracks {
  margin-top: 50px;
}

.list-of-tracks {
  li {
    background: $white;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &:hover {
      // background: rgba(221, 78, 82, 0.1);
      background: rgba(0, 0, 0, 0.1);
    }
    img, .info-works {
      cursor: pointer;
    }
  }
  .track-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .list-of-tracks {
    li {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@import "./constants.scss";

.radios {
  padding-bottom: 50px;
  .less-then-4 {
    .slick-slide {
      max-width: 374px;
    }
  }

  .head-playlist {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 60px;
    .title {
      font-family: "Playfair Display", serif;
      font-weight: 600;
      font-style: normal;
      font-size: 28px;
    }
  }
  .slide-buttons {
    display: none;
    flex-direction: row;
    &.more-then-3 {
      display: flex;
    }
    img {
      margin-left: 30px;
      cursor: pointer;
    }
  }

  .list-of-radios {
    .radio-container {
      position: relative;

      &:hover .heart-icon {
        opacity: 1;
      }
    }

    .box-playlist {
      width: 30%;
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    .slick-slide,
    .box-playlist {
      a {
        padding: 16px;
        margin-bottom: 16px;
        cursor: pointer;
        position: relative;
        color: $black;
        display: block;
        margin: 16px;
        &:hover {
          text-decoration: none;
          box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
        }
      }
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      .play-radio {
        position: absolute;
        right: 24px;
        top: 160px;
        cursor: pointer;

        @media only screen and (max-width: $breakpoint_658) {
          top: 200px;
        }

        img {
          width: 48px;
          height: 48px;
        }
      }
      .heart-icon {
        opacity: 0;
        transition: opacity 200ms ease-in-out;
        position: absolute;
        left: 40px;
        top: 160px;
        img {
          width: 48px;
          height: 48px;
        }

        @media only screen and (max-width: $breakpoint_658) {
          left: 24px;
          top: 200px;
        }

        @media only screen and (max-width: $breakpoint_1024) {
          display: block;
        }
      }
    }
    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin: 16px 0 0 0;
    }
  }
}

.page-counter {
  display: flex;
  justify-content: center;

  a {
    white-space: pre;
    text-decoration: none;

    &.number {
      color: #e9dada;

      &--selected {
        color: #e68484;
      }
    }
  }
}
// Jazz
.radios.jazz {
  .page-counter {
    a {
      &.number {
        color: #cfd3e2;

        &--selected {
          color: #7089e4;
        }
      }
    }
  }
}

.more-loader--midaligned {
  text-align: center;

  > a {
    > div {
      margin: auto;
    }
  }
}

@media only screen and (max-width: $breakpoint_1200) {
  .radios {
    &__header {
      .content {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .radios {
    .list-of-radios {
      .box-playlist {
        width: 49%;
      }
    }
    .slide-buttons {
      &.more-then-1 {
        display: flex;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .radios {
    .list-of-radios {
      .box-playlist {
        width: 100%;
      }
      li a,
      .slick-slide a,
      .box-playlist a {
        padding: 16px 0;
        margin: 0;
      }
      .slick-slide,
      .box-playlist {
        img {
          height: 260px;
        }
      }
    }
    .slide-buttons {
      &.more-then-1 {
        display: flex;
      }
    }
    .less-then-4 {
      .slick-slide {
        max-width: 100%;
      }
    }
  }
}

.account {
  .loading-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lang-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    img {
      margin-right: 8px;
    }
  }
}
.header-account {
  background: linear-gradient(120.91deg, #141140 38.41%, #dd4e52 100%);
  padding: 48px 0;
  .content {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 42px;
    line-height: normal;
    color: $white;
  }
}
.account-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  padding-bottom: 80px;
  &__left,
  &__right {
    width: 48%;
  }
  .box {
    border: 1px solid $grey_vlight;
    border-radius: 8px;
    margin-bottom: 33px;
    .head {
      background: $grey_vlight;
      border-radius: 8px 8px 0px 0px;
      padding: 10px 24px;
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
    }

    &.restricted {
      border-color: $red;
      .head {
        background: $red;
        color: white;
      }
      .tabs {
        border-color: $red;
      }
      .pill {
        position: relative;
        padding: 4px;
        margin-left: 10px;
        font-family: "Courier New", Courier, monospace;
        color: $black;
        &::before {
          border-radius: 5px;
          border: 1px solid $red;
          background-color: #dcdfe4;
          opacity: 0.5;
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          content: "";
        }
      }
    }
    .details {
      font-size: 20px;
      line-height: 35px;
      padding: 20px 24px;
    }
    .tabs {
      padding: 12px 24px;
      border-top: 1px solid $grey_vlight;
      font-size: 16px;
      line-height: 23px;
      color: $grey_light;
      span {
        margin-right: 24px;
        cursor: pointer;
      }
      .red {
        color: $red;
      }
      .link {
        color: $black;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
.head-subscription {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  > span {
    max-width: 270px;
  }
  .error {
    font-size: 16px;
  }
}
.button-upgrade,
.button-danger {
  display: flex;
  align-items: center;
  border: 1px solid $green;
  border-radius: 100px;
  color: $green;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 20px;
  height: 32px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    background: $green;
    color: $white;
  }
}

.button-danger {
  border: 1px solid $red;
  background-color: $red;
  color: $white;
  &:hover {
    border: 1px solid $red;
    text-decoration: none;
    background: $red_black;
    color: $white;
  }
}

.subscription-info {
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
  }
  .label {
    color: $grey_light;
    font-size: 14px;
    margin-right: 16px;
    min-width: 40px;
  }
}
.support-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
  img {
    margin-right: 12px;
  }
  a {
    text-decoration: underline;
    color: $black;
  }
  .phone-link {
    text-decoration: none;
  }
}
.unsubscribe {
  padding: 36px 80px;
  max-width: 560px;
  .head {
    font-weight: bold;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 25px;
  }
  .text {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 30px;
  }
  .button-close {
    border: 1px solid $grey_light;
    box-sizing: border-box;
    border-radius: 100px;
    font-size: 16px;
    font-weight: bold;
    color: $grey_light;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    max-width: 140px;
    margin: 0 auto;
    cursor: pointer;
  }
  a {
    color: $red;
  }
}
.icon-close {
  display: none;
}

// Jazz
.jazz {
  .header-account {
    background: linear-gradient(120.91deg, #141140 38.41%, #4866e0 91.66%);
  }
  .account-details {
    .box {
      .tabs {
        .red {
          color: $blue;
        }
      }
    }
  }
  .button-upgrade {
    border-color: $blue;
    color: $blue;
    &:hover {
      background: $blue;
      color: $white;
    }
  }
  .change-password {
    .save {
      background: $blue;
    }
  }
}
.unsubscribe.jazz {
  a {
    color: $blue;
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .account-details {
    flex-direction: column;
    height: unset;
    padding-bottom: 70px;
    &__left,
    &__right {
      width: 100%;
    }
    .box {
      .tabs {
        .link {
          display: block;
        }
      }
    }
  }
  .unsubscribe {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .icon-close {
    display: block;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
  .head-subscription {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .button-upgrade {
    margin-top: 10px;
  }
}

.delete-modal-content {
  max-width: 600px;

  .button-red, .button-blue {
    min-width: 140px;
  }
}

.article-detail {
  .error {
    text-align: center;
    font-size: 16px;
    padding: 50px 0;
  }
  .loading-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.header-article {
  background: $white;
  padding: 64px 0;
  position: relative;
  margin-bottom: 64px;
  .gradient {
    position: absolute;
    top: 50%;
    margin-top: -120px;
    right: 0;
    width: 54.5%;
    height: 240px;
    background: linear-gradient(101.44deg, #141140 38.41%, #dd4e52 100%);

    &.jazz {
      background: linear-gradient(110.01deg, #141140 38.41%, #4866e0 91.66%);
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      display: inline-block;
      width: 576px;
      height: 400px;
      object-fit: cover;
      position: relative;
      z-index: 2;
    }
  }
  .label-category-name {
    font-size: 16px;
    line-height: 28px;
    color: $red;
    margin-bottom: 25px;
    margin-top: 10px;
  }
  .category-name {
    max-width: 384px;
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 42px;
    line-height: normal;
    color: $black;
    margin-bottom: 25px;
    margin-top: 10px;
  }
  .desc-category {
    color: $grey_light;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 45px;
    max-width: 320px;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    .button-red,
    .button-blue {
      display: flex;
      min-width: 160px;
      height: 40px;
      justify-content: center;
      align-items: center;
      margin: 0 12px 0 0;
    }
    .button-red-border {
      border-color: $red;
      color: $red;
    }
    .button-blue-border {
      border-color: $blue;
      color: $blue;
    }
    .button-red-border,
    .button-blue-border {
      display: flex;
      min-width: 160px;
      height: 40px;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      border: 1px solid;
      border-radius: 100px;
      cursor: pointer;
    }
    img {
      margin-right: 10px;
    }
  }
}
.list-playlist {
  max-width: 930px;
  margin: 0 auto;
  border: 2px solid $black;
  margin-bottom: 48px;
}
.head-playlist-article {
  height: 47px;
  background: $black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $white;
  line-height: 28px;
  .arrow-up {
    transform: rotate(180deg);
  }
  &__left {
    padding-left: 16px;
    img {
      margin-right: 16px;
    }
  }
  &__right {
    padding-right: 16px;
    cursor: pointer;
    img {
      margin-left: 10px;
    }
  }
  &__left,
  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.list-of-playlist {
  .mc-heart {
    margin-left: -10px;
    margin-right: 10px;
  }
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
  }
  &__blue {
    &:hover {
      background: $blue_light;
    }
    .active {
      color: $blue;
    }
  }
  &__red {
    &:hover {
      background: $red_light;
    }
    .active {
      color: $red;
    }
  }
  &__left,
  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__left {
    padding-right: 30px;
    cursor: pointer;
  }
  .index {
    min-width: 32px;
    text-align: center;
    img {
      display: inline-block;
    }
  }
  .iconMore {
    width: 32px;
    height: 32px;
  }
  .details-song {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .row {
      padding: 2px 0;
      line-height: 23px;
    }
    .sub-row {
      color: $grey_light;
    }
  }
  .bold {
    font-weight: bold;
    margin-right: 8px;
  }
}
.content-article {
  max-width: 930px;
  margin: 30px auto;
  font-family: "Roboto", sans-serif !important;
  font-size: 18px !important;
  color: $black !important;
  line-height: 32px !important;
  blockquote {
    font-style: italic;
    font-family: Georgia, Times, "Times New Roman", serif;
    border-style: solid;
    border-color: #ccc;
    border-width: 0;
    padding-left: 20px;
    padding-right: 8px;
    border-left-width: 5px;
  }
  &.quiz {
    height: 630px;
  }
  div,
  font {
    font-family: "Roboto", sans-serif !important;
    font-size: 18px !important;
    line-height: 32px !important;
    color: $black !important;
  }
  p {
    margin-bottom: 20px;
  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }
  h3,
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 42px;
    margin: 15px 0;
  }
  h3 {
    font-size: 21px;
  }
  img,
  video,
  iframe {
    max-width: 100%;
  }
}
.also-like {
  padding: 64px 0;
  .header {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    margin-bottom: 24px;
  }
  .list-recommend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    li {
      width: 32%;
      background: $white;
      padding: 16px;
      position: relative;
      .cover-image {
        width: 100%;
        max-height: 250px;
        min-height: 250px;
        margin-bottom: 16px;
        object-fit: cover;
      }
      .play-radio {
        position: absolute;
        top: 230px;
        right: 30px;
        cursor: pointer;
      }
    }
    a {
      color: $black;
      &:hover {
        color: $black;
        text-decoration: none;
      }
    }
    .label {
      border-radius: 0px 0px 4px 4px;
      display: inline-block;
      font-size: 14px;
      color: $white;
      padding: 5px 8px;
      position: relative;
      top: -16px;
      &.brown {
        background: $brown;
      }
      &.green {
        background: $green;
      }
      &.purple {
        background: $purple;
      }
    }
    .title {
      font-size: 20px;
      line-height: 28px;
      font-weight: bold;
    }
  }
  .recommend-box {
    cursor: pointer;
  }
}
.logged-out-banner {
  background: $red;
  padding: 12px 0;
  color: $white;
  &.mask {
    opacity: 0;
  }
  &.fixed {
    position: sticky;
    z-index: 10;
    top: 68px;
    width: 100%;
  }
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .button-signup {
    background: $white;
    color: $red;
    font-weight: bold;
    border-radius: 100px;
    padding: 10px 32px;
    cursor: pointer;
    min-width: 120px;
    margin-left: 20px;
    white-space: nowrap;
    display: inline-block;
    &:hover {
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: $breakpoint_1200) {
  .article-detail {
    .header-article {
      &__left {
      }
      &__right {
        img {
          max-width: 100%;
          width: auto;
          height: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_1024) {
  .article-detail {
    .header-article {
      .gradient {
        width: 53%;
      }
      .category-name {
        font-size: 40px;
      }
    }
    .list-recommend {
      flex-wrap: wrap;
      li {
        width: 48%;
        margin-bottom: 20px;
      }
    }
  }
  .logged-out-banner {
    &__left {
      font-size: 15px;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .article-detail {
    .header-article {
      padding-top: 10px;
      .gradient {
        width: 50%;
      }
      &__left {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__right {
        width: 47%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .buttons {
        .button-red,
        .button-blue,
        .button-red-border,
        .button-blue-border {
          min-width: 0;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
  .logged-out-banner {
    &__left {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: $breakpoint_768) {
  .article-detail {
    .header-article {
      padding: 32px 0;
      .gradient {
        width: 100%;
        height: 120px;
        top: 100%;
        margin-top: -192px;
      }
      .content {
        flex-direction: column;
      }
      .label-category-name {
        margin-bottom: 15px;
        margin-top: 0px;
      }
      .category-name {
        font-size: 32px;
        max-width: 100%;
        margin-bottom: 15px;
      }
      .desc-category {
        max-width: 100%;
        margin-bottom: 10px;
      }
      .buttons {
        margin-bottom: 32px;
      }
      &__left {
        width: 100%;
      }
      &__right {
        width: 100%;
        img {
          width: 288px;
          height: 200px;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .article-detail {
    .header-article {
      margin-bottom: 0px;
    }
    .content-list-playlist {
      padding: 0;
    }
    .list-playlist {
      margin-bottom: 16px;
    }
    .content-article {
      margin-top: 0;
      padding: 0 16px;
    }
    .also-like {
      padding: 32px 0;
    }
    .list-recommend {
      li {
        width: 100%;
      }
    }
  }
  .logged-out-banner {
    &.fixed-banner {
      top: 172px;
    }
    &__left {
      text-align: center;
    }
    .content {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .button-signup {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
}

.content-loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: $grey_mlight;
}
.info-webplayer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.75s;
  .icon-more .more-menu li ul {
    top: 0px;
    bottom: auto;
  }
  &.collapsed {
    transform: translate3d(0, 120%, 0);
  }
}

.content-webplayer {
  font-family: $roboto;
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
  .top,
  .bottom {
    .content {
      display: flex;
    }
  }
  .top {
    height: 55%;
    padding-top: 50px;
    background-color: rgba(255, 255, 255, 0.5);
    position: relative;
    .overlay {
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      filter: blur(10px);
      background-repeat: no-repeat;
      background-size: cover;
    }
    @media only screen and (max-width: $breakpoint_1024) {
      height: 50%;
    }
    @media only screen and (max-width: $breakpoint_880) and (orientation: landscape) {
      height: 45%;
      padding-top: 10px;
    }
    @media only screen and (max-width: $breakpoint_658) {
      height: 35%;
      padding-top: 20px;
    }
    .right-buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      @media only screen and (max-width: $breakpoint_658) {
        display: none;
      }
      .button-white-outlined {
        min-width: 100px;
        background: $white;
      }
    }

    .content {
      height: 100%;
      position: relative;
      z-index: 1;
    }
    .track-info-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .playing-from-link {
        border-radius: 25px;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 5px;
      }
      @media only screen and (max-width: $breakpoint_658) {
        flex-direction: column;
      }
    }
    .track-image {
      bottom: -25px;
      position: relative;
      height: 250px;
      width: 250px;
      min-width: 250px;
      @media only screen and (max-width: $breakpoint_658) {
        margin-left: auto;
        margin-right: auto;
        bottom: 0;
      }
      @media only screen and (max-width: $breakpoint_880) and (orientation: landscape) {
        height: 100px;
        width: 100px;
        min-width: 100px;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        top: 0;
      }
    }
    .track-info {
      padding-top: 20px;
      padding-bottom: 25px;
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @media only screen and (max-width: $breakpoint_658) {
        display: none;
      }
    }
  }
  .bottom {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    height: 50%;
    @media only screen and (max-width: $breakpoint_658) {
      height: 60%;
      padding-top: 40px;
      .left {
        max-width: 80%;
        width: 80%;
      }
    }
    .left {
      max-width: 65%;
    }
    .track-title {
      padding-bottom: 0px;
      margin-bottom: 3px;
      line-height: 1.4;
    }
    .content {
      flex-direction: column;
      .title-section {
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 10px;
        margin-bottom: 10px;
        @media only screen and (max-width: $breakpoint_880) {
          padding-bottom: 0px;
        }
        .track-title {
          font-size: 24px;
        }
        .track-artist {
          font-size: 16px;
        }
        @media only screen and (max-width: $breakpoint_658) {
          .track-title {
            font-size: 18px;
          }
          .track-artist {
            font-size: 14px;
          }
        }
      }
    }
    .webplayer {
      .left,
      .right {
        flex: 2;
      }
      .center {
        flex: 3;
      }
    }
  }

  a {
    color: $black;
    &:visited {
      color: $black;
    }
  }
  li {
    list-style: none;
  }
}
.info-webplayer {
  transition: all 0.75s;
  .icon-more .more-menu li ul {
    top: 0px;
    bottom: auto;
  }
  &.collapsed {
    transform: translate3d(0, 120%, 0);
  }
  .content-webplayer {
  }
  .tabs {
    box-shadow: inset 0px -1px 0px #dcdfe4;
    border-radius: 0px 8px 0px 0px;
    padding: 0 20px;
    font-family: "Playfair Display", serif;
    line-height: normal;
    font-size: 22px;
    font-weight: 600;
    color: $grey_light;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 68px;
    position: absolute;
    z-index: 1;
    background: $white;
    li {
      margin: 0 20px;
      height: 68px;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        color: $black;
      }
      &.active {
        color: $black;
        &:before {
          content: "";
          display: block;
          width: 60%;
          height: 3px;
          background: $red;
          margin: 0 auto;
          position: absolute;
          bottom: 0px;
        }
      }
    }
  }
  .content-tabs {
    padding: 108px 40px 40px 40px;
    overflow: auto;
    flex-grow: 1;
    height: 100%;
  }

  .list-of-queue {
    height: 100%;
    width: 80%;
    margin-left: auto;
    overflow-y: auto;
    margin-bottom: 25px;
    background-color: rgba(255, 255, 255, 0.15);
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation: fadeIn 1s ease both;
    animation-iteration-count: 1;
    scrollbar-color: $grey_dark $greyLight;
    scrollbar-width: thin;
    &.mobile {
      width: 100%;
      margin-left: unset;
      position: relative;
      li {
        border-radius: 5px;
      }
      .list-of-queue__composer {
        padding-right: 15px;
        .icon-playing {
          width: 48px;
          height: 48px;
          img {
            width: 24px;
            height: 24px;
          }
        }
        img {
          width: 48px;
          height: 48px;
          border-radius: 10px;
        }
      }
      .list-of-queue__info-track {
        line-height: 23px;
        font-size: 16px;
        cursor: pointer;
        .composer-text,
        .track-title {
          @extend .truncate;
        }
      }
    }
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: $grey_light;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $grey_dark;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: $grey_dark;
    }
    li {
      transition: all 200ms;
      padding: 21px 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      border-radius: 4px;

      :nth-child(3) {
        margin-left: auto;
      }

      &.current-track {
        color: $red;

        &.jazz {
          color: $blue;
        }
      }
      .icon-playing {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
      }
      &:hover {
        background: rgba(255, 255, 255, 0.3);

        .icon-more-red {
          display: block;
        }
        .image-composer {
          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: "";
            display: block;
            background: $black;
            opacity: 0.75;
            border-radius: 50%;
          }
          .icon-play-circle-red {
            display: block;
          }
        }
      }
    }
    .image-composer {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-play-circle-red {
        position: absolute;
        width: 48px;
        height: 48px;
        padding: 8px;
        display: none;
        cursor: pointer;
      }
    }
    .icon-more-red {
      position: absolute;
      right: 16px;
      top: 50%;
      width: 32px;
      height: 32px;
      margin-top: -16px;
      display: none;
      cursor: pointer;
    }
    &__composer {
      padding-right: 15px;
      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
    }
    &__info-track {
      line-height: 23px;
      font-size: 16px;
      cursor: pointer;
    }
    .composer-text {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  .listen-history {
    margin-top: 40px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    color: $red;
    cursor: pointer;

    &.jazz {
      color: $blue;
    }

    img {
      margin-right: 10px;
    }
  }
}

.iconHide-desktop {
  display: block;
  cursor: pointer;
}
.iconHide-mobile {
  display: none;
}
.track-info-title {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
}
.suggestions {
  li {
    border: 1px solid $grey_vlight;
    border-radius: 4px;
    margin-bottom: 28px;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    min-height: 180px;
    &:hover {
      box-shadow: 0px 4px 8px rgba(143, 153, 170, 0.5);
    }
    &:last-child {
      margin-bottom: 0;
    }
    .label-suggest {
      position: absolute;
      top: -1px;
      left: 24px;
      border-radius: 0px 0px 4px 4px;
      color: $white;
      padding: 0 8px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      &.brown {
        background: $brown;
      }
      &.green {
        background: $green;
      }
      &.purple {
        background: $purple;
      }
    }
    .title-suggest {
      font-weight: bold;
      line-height: 25px;
      font-size: 18px;
      margin-bottom: 10px;
      padding: 44px 0 0 0px;
    }
    .desc-suggest {
      line-height: 23px;
      font-size: 16px;
      padding-bottom: 24px;
    }
  }
  &__left {
    padding-right: 24px;
    width: 60%;
  }
  &__right {
    width: 40%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: fill;
      border-radius: 0 4px 4px 0;
    }
  }
}

.iconHeart {
  margin-right: 16px;
  margin-left: -16px;
  position: relative;
}

.box-track-info {
  padding-left: 40px;
  position: relative;
  a {
    color: inherit;
  }
  .icon-track-article {
    position: absolute;
    top: 0px;
    left: 0;
  }
  .label {
    color: $grey_light;
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 6px;
  }
  li {
    margin-bottom: 15px;
  }
}
.mini-player {
  width: 100%;
  background: $white;
  height: 88px;
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 10;
  outline: none;
  &.expanded {
    height: unset;
    &.collapse {
      transform: translate3d(0, 200px, 0);
    }

    .duration {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: $grey_light;
      font-size: 14px;
      line-height: 20px;
      margin-top: -5px;
    }
  }
  &.hidden {
    display: none;
  }
  .player {
    position: absolute;
    bottom: 0;
    width: 1px !important;
    height: 1px !important;
  }
  .duration {
    display: none;
  }
  &__controls {
    width: 100%;
    padding: 0 25px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    &.docked {
      padding: 0;
      .stream-settings {
        margin-right: 0;
      }
    }
    cursor: pointer;
    img {
      cursor: pointer;
      display: block;
    }
    &.jazz {
      cursor: default;
    }
    &-left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media only screen and (max-width: $breakpoint_658) {
        display: unset;
        margin-right: 10px;
      }
      .rc-slider {
        top: 2px;
      }
    }
    &-bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .iconVolume,
      .icon-settings img {
        height: 20px;
        cursor: pointer;
      }
    }
  }
  .volume-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
    .rc-slider {
      top: 5px;
    }
    .rc-slider-rail,
    .rc-slider-track {
      height: 4px;
      &:hover {
        height: 6px;
      }
    }
    .rc-slider-handle {
      height: 4.3px;
      width: 4px;
      margin: 0;
    }
    .iconVolume {
      height: 16px;
    }
  }
  .iconVolume {
    margin-right: 15px;
    &.disabled {
      opacity: 0.25;
    }
  }
  .iconExpand {
  }
  .icon-more {
    .more-menu {
      right: 8px;
      bottom: 52px;

      &:after {
        transform: unset;
        right: 8px;
      }
    }
  }
  .box-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: $breakpoint_880) {
      margin-right: 40px;
      .mc-heart {
        margin-right: 40px;
      }
    }
    @media only screen and (max-width: $breakpoint_880) {
      .mc-heart {
        margin-right: 20px;
      }
    }
    @media only screen and (max-width: $breakpoint_658) {
      .mc-heart {
        margin-right: 0px;
      }
    }
    .icon-more,
    .iconCollapse,
    .iconNext,
    .iconPrev {
      cursor: pointer;
      display: block;
    }
    .iconNext,
    .iconPrev {
      img {
        width: 20px;
        height: 20px;
      }
    }
    .icon-more,
    .iconCollapse {
      img {
        width: 25px;
        height: 25px;
      }
    }
    img {
      width: 40px;
      height: 40px;
    }
    .iconHeart img {
      padding: 5px;
    }
    .iconHeart,
    .iconPrev,
    .iconNext {
      &.disabled {
        opacity: 0.3;
        img {
          cursor: default;
        }
      }
    }
  }
  .stream-settings {
    display: block;

    &.mobile {
      display: none;
    }
  }
  .iconPausePlay {
    margin: 0 16px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    &.loading {
      &:before {
        content: "";
        position: absolute;
        left: 4px;
        top: 4px;
        width: 36px;
        height: 36px;
        border-color: transparent $black $black $black;
        border-style: solid;
        border-width: 3px;
        border-radius: 50%;
        -webkit-animation-name: rotate;
        animation-name: rotate;
        animation: rotate 1s ease;
        animation-iteration-count: infinite;
      }
    }
  }
  .mini-image-composer {
    margin-right: 16px;
    height: 88px;
    width: auto;
    img {
      width: 48px;
      height: 48px;
      object-fit: cover;
      height: 100%;
      width: auto;
    }
  }
  .song-info {
    color: $black;
    display: flex;
    flex-direction: column;
    &__row {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      align-items: center;
    }
    &__composer {
      font-weight: bold;
      line-height: 23px;
      margin-right: 8px;
    }
    &__name {
      line-height: 23px;
      &.bold {
        font-weight: bold;
      }
    }
    &__performances {
      color: $grey_light;
      margin-right: 20px;
      .artist {
        height: 17px;
        overflow: hidden;
        > a {
          color: #8f99aa;
        }
      }
    }
    &__duration {
      margin-left: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 20px;
      font-size: 14px;
      color: $grey_light;
      margin-right: 30px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }
}
.slider-box {
  width: 120px;
  margin-right: 5px;
}
.control-seconds {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  &__slider {
    width: 100%;
  }
  .rc-slider-rail,
  .rc-slider-track {
    border-radius: 0;
  }
  .rc-slider-track {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
}
.theme-slider {
  .rc-slider {
    padding: 0;
  }
  .rc-slider-rail {
    background-color: $grey_vlight;
    height: 8px;
  }
  .rc-slider-disabled {
    background: transparent;
  }
  .rc-slider-track {
    background-color: $black;
    height: 8px;
  }
  .rc-slider-handle {
    width: 16px;
    height: 16px;
    margin-top: -4px;
    background-color: $black;
    border-color: $black;
    outline: none;
    &:hover {
      border-color: $black;
      &:before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        background: $white;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 2px;
      }
    }
    &:focus,
    &:active {
      border-color: $black;
    }
  }
}
.control-seeking {
  .rc-slider-handle {
    &:before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      background: $white;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }
}
.rc-slider-tooltip {
  z-index: 11;
}
.rc-slider-tooltip-placement-top {
  padding: 0;
}
.rc-slider-tooltip-content {
  margin-top: -7px;
}
.rc-slider-tooltip-inner {
  background: $black;
  opacity: 0.9;
  border-radius: 4px;
  line-height: 20px;
  font-size: 14px;
  color: $white;
  height: 28px;
  padding: 4px 8px;
}

@media only screen and (max-width: $breakpoint_1024) {
  .info-webplayer {
    .content-webplayer {
      .info-composer {
        .name-composer {
          font-size: 26px;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .mini-player {
  }
  .info-webplayer {
    .iconHide {
      top: 84px;
      right: 17px;
    }
  }
}

@media only screen and (max-width: $breakpoint_658) {
  .mini-player {
    height: 66px;
    .mini-image-composer {
      height: 66px;
    }
    .song-info {
      &__row {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
        font-size: 14px;
      }
      &__duration,
      &__performances {
        display: none;
      }
    }
    &__controls {
      padding: 0 16px;
    }
    &__controls-right {
      .stream-settings {
        &.mobile {
          display: none;
        }
      }
      .stream-settings,
      .iconHeart,
      .iconNext,
      .iconPrev {
        display: none;
      }
      .box-icons {
        padding-right: 0;
        .iconNext,
        .iconPrev {
          display: none;
        }
      }
    }
    .iconPausePlay {
      margin-right: 0;
    }
    .icon-more {
      display: none;
    }
    &.expanded {
      padding: 16px;
      height: 130px;
      flex-direction: column;

      .mini-player__controls {
        justify-content: center;
      }
      .mini-player__controls-left {
        display: none;
      }
      .mini-player__controls-right {
        width: 100%;
        justify-content: space-evenly;

        .icon-more {
          display: block;
          .iconMore {
            width: 32px;
            height: 32px;
          }
          .more-menu {
            right: 3px;
            bottom: 46px;

            &:after {
              transform: unset;
              right: 8px;
            }
          }
        }
      }
      .control-seconds {
        position: static;
        width: 100%;
        flex-direction: column;
        .rc-slider-rail,
        .rc-slider-track {
          border-radius: 100px;
        }
      }

      .mini-player__controls-right {
        .box-icons {
          align-items: center;
        }
        .stream-settings {
          &.mobile {
            display: block;
            &.controls {
              width: 32px;
              height: auto;
            }
          }
        }
        .iconCollapse,
        .iconNext,
        .iconPrev {
          display: block;
          padding: 15px;
          img {
            width: 32px;
            height: 32px;
          }
        }
        .iconHeart {
          display: none;
        }
        .iconPausePlay {
          margin: 0 16px;
          position: relative;

          &.loading {
            &:before {
              left: 4px;
              top: 4px;
              width: 56px;
              height: 56px;
              border-width: 3px;
            }
          }
        }
      }
      .iconPausePlay {
        margin: 0 20px;
        img {
          width: 60px;
          height: 60px;
        }

        &.loading {
          &:before {
            left: 5px;
            top: 5px;
            width: 56px;
            height: 56px;
          }
        }
      }
    }
  }
  .iconHide-desktop {
    display: none;
  }
  .iconHide-mobile {
    display: block;
    cursor: pointer;
  }
  .info-webplayer {
    .iconHide {
      top: 16px;
      right: 14px;
    }
    .listen-history {
      margin-top: 30px;
    }
  }
  .suggestions {
    &__left {
      display: flex;
      align-items: center;
    }
    li {
      min-height: 120px;
      .title-suggest {
        padding: 0;
        margin: 0;
      }
      .desc-suggest {
        display: none;
      }
    }
  }
}
.hide-webplayer {
  .container-webplayer {
    display: none;
  }
}

.mini-player {
  &.expanded {
    .box-icons {
      margin-right: 0;
      justify-content: space-evenly;
      .iconCollapse {
        margin-right: 16px;
        @media only screen and (max-width: $breakpoint_658) {
          margin-right: 0;
        }
      }
      .icon-more {
        margin-left: 16px;
        .mc-heart {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: $breakpoint_518) {
        .list-of-queue {
          .icon-more {
            margin-left: auto;
          }
        }
        .icon-more {
          margin-left: 0;
        }
        .mobileIconMore,
        .iconPausePlay,
        .iconCollapse,
        .iconNext,
        .iconPrev {
          padding: 5px;
          margin: 5px;
        }
      }
      .iconCollapse {
        display: flex;
      }
    }
    .theme-slider {
      .rc-slider-track,
      .rc-slider-rail {
        height: 6px;
        border-radius: 20px;
      }
      .rc-slider-handle {
        height: 5.3px;
        width: 5px;
        margin-top: 0px;
        margin-left: -1px;
        border-radius: 20px;
      }
    }

    .volume-container {
      .rc-slider {
        top: 5px;
      }
      .rc-slider-rail,
      .rc-slider-track {
        height: 4px;
        &:hover {
          height: 6px;
        }
      }
      .rc-slider-handle {
        height: 4.3px;
        width: 4px;
      }
    }
  }
}

.bottom-panel {
  background-color: $grey_dark;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  bottom: 55px;
  position: absolute;
  left: 10px;
  right: 10px;
  img {
    height: 20px;
  }

  li,
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start !important;
    color: $black;
    padding: 15px 16px !important;
    text-decoration: none;
    &:hover {
      background: transparent !important;
    }
    img {
      margin-right: 10px;
    }
  }
}

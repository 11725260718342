.header-albums {
  background: linear-gradient(120.07deg, #141140 38.41%, #4866e0 100%);
  padding: 32px 0;
  height: 151px;
}
.albums-title {
  font-family: "Playfair Display", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 48px;
  color: $white;
}
.albums-desc {
  line-height: 28px;
  color: $white;
  margin-top: 20px;
}
.loading-albums {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 62px 0;
}
.featured-albums {
  .appears-on-star {
    display: none;
  }
}
.featured-albums,
.albums-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px 3%;
  @media only screen and (max-width: $breakpoint_880) {
    grid-template-columns: 1fr 1fr;
  }

  .album-component {
    @extend .bordered;
    @extend .hover-darken;
    @extend .hover-shadow;
    border-radius: $card_border_radius;
    margin-top: 48px;
    display: flex;
    position: relative;

    .label {
      border-radius: 0px 0px 4px 4px;
      background: $blue;
      position: absolute;
      top: 0;
      left: 16px;
      padding: 2px 8px;
      font-size: 14px;
      line-height: 20px;
      color: $white;
      z-index: 2;
    }
  }
  a {
    border-radius: $card_border_radius;
    position: relative;
    padding: 40px 16px 16px 16px;
    display: block;
    text-decoration: none;
    &:hover {
      .feature-composer-info {
        &__photo {
          img {
            border-color: $red;
          }
        }
      }
    }
    .image {
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $card_border_radius;
      .image-album {
        max-width: 100%;
        max-height: 224px;
        object-fit: cover;
      }
    }
    .name {
      line-height: 120%;
      font-weight: bold;
      margin-bottom: 8px;
      color: $black;
    }
    .artists {
      color: $black;
      line-height: 120%;
      word-break: break-word;
      span {
        &:after {
          content: "•";
          margin: 0 5px;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
.albums-list {
  margin-bottom: 48px;
  .album-component {
    margin-top: 32px;
    a {
      padding-top: 16px;
    }
  }
}
.albums {
  .space-filters {
    height: 64px;
  }
  .space-search-result {
    height: 93px;
  }
  &__filters {
    background: $white;
    box-shadow: inset 0px -1px 0px #dcdfe4;
    background: $white;
    height: 64px;
    &.fixed {
      position: fixed;
      z-index: 2;
      top: 68px;
      left: 0;
      right: 0;
    }
    .content {
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      &__left,
      &__right {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &__search-box {
        width: 100%;
      }
      .input-search {
        width: 100%;
        height: 29px;
        border: none;
        font-size: 16px;
        line-height: 28px;
        color: $black;
        margin: 0;
        padding: 0;
      }
      .icon-enter {
        cursor: pointer;
      }
      .icon-close-search {
        margin-left: 40px;
        cursor: pointer;
      }
      .filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
      }
      .filter-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        &.active {
          color: $red;
        }
        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
      .filter-buttons {
        border-top: 1px solid $grey_vlight;
        background: $white;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .clear-filters {
          color: $grey_light;
          font-weight: bold;
          cursor: pointer;
        }
        .button-red {
          margin: 0;
          height: 40px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 150px;
          padding: 0 15px;
        }
      }
      .amount-filters {
        background: $grey_vlight;
        border-radius: 8px;
        font-size: 14px;
        font-weight: normal;
        margin-right: 20px;
        padding: 5px 8px;
        &.all {
          margin-right: 0;
          margin-left: 8px;
        }
      }
    }
  }
  .search-result {
    background: $grey_mlight;
    height: 93px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.fixed {
      position: fixed;
      z-index: 1;
      top: 132px;
      width: 100%;
      &.fixed-free {
        top: 180px;
      }
    }
  }
  .result-search {
    font-size: 20px;
    line-height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 8px;
    }
  }
  .no-result {
    max-width: 410px;
    margin: 0 auto;
    text-align: center;
    line-height: 28px;
    img {
      width: 74px;
      height: 66px;
      display: block;
      margin: 64px auto 16px auto;
    }
  }
  .search-result-text {
    line-height: 28px;
    color: $grey_light;
  }
  .clear-search {
    cursor: pointer;
  }
  .sort-menu,
  .box-filter-menu {
    min-width: 350px;
    background: $white;
    border: 1px solid $grey_vlight;
    padding: 10px 16px;
    position: absolute;
    z-index: 1002;
    top: 43px;
    display: none;
    &.active {
      display: block;
    }
    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      cursor: pointer;
    }
    .header-filter {
      display: none;
      justify-content: center;
      align-items: center;
      padding: 18px;
      text-align: center;
      box-shadow: inset 0px -1px 0px #dcdfe4;
      position: relative;
      height: 52px;
      .close-filter {
        position: absolute;
        top: 50%;
        margin-top: -16px;
        right: 11px;
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
    .filters-menu {
      max-height: 210px;
      overflow: auto;
      li {
        margin: 0;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: none;
        font-weight: normal;
        color: $black;
        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
    }
    .sort-menu {
      left: 4px;
      right: 4px;
    }
  }
}


@media only screen and (max-width: $breakpoint_658) {
  .header-albums {
    padding: 0px;
    display: flex;
    align-items: center;
  }
  .albums-desc {
    margin-top: 5px;
  }
}


.albums-list {
  margin-bottom: 43px;
  margin-top: 27px;
  .album-component {
    @extend .bordered;
    list-style: none;
    height: 100%;
    margin-top: 0;
    margin-right: 10px;
    border-radius: $card_border_radius;
    width: unset;
    &:hover {
      background-color: $grey_mlight;
      border-color: transparent;
    }

    > span:first-child {
      margin-left: 27px;
      position: absolute;
      z-index: 1;
      padding: 4px;
      background: $brown;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      color: $white;
      text-transform: capitalize;
    }

    a {
      border-radius: $card_border_radius;
      height: 100%;
      padding: 16px;
      .name {
        word-wrap: break-word;
      }
      .image {
        position: relative;
        .appears-on-star {
          .star {
            width: 25px;
            height: 25px;
            position: absolute;
            top: -5px;
            right: -5px;
          }
        }
      }
      .image-album {
        border-radius: $card_border_radius;
        height: 224px;
        object-fit: cover;
        width: 100%;

        @media only screen and (max-width: $breakpoint_1200) {
          height: 181px;
          width: 181px;
        }

        @media only screen and (max-width: $breakpoint_658) {
          height: 125px;
          width: 125px;
        }
      }
    }
  }
}
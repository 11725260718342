@import "./constants.scss";

.proud {
  background-color: $white;
  .box-proud-content {
    text-align: center;
    img {
      display: inline-block;
      max-width: 100%;
    }
  }
  .box-proud {
    padding: 110px 0 90px 0;
  }
  .header-txt {
    text-align: center;
    padding-bottom: 36px;
  }

  .sub-txt {
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    color: #8f99aa;
  }

  .partners {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0px;

    img {
      margin: 20px;
      max-width: 130px;
      max-height: 70px;
    }
  }
}
.quote {
  background: $red;
  padding: 80px 0;
  text-align: center;
  color: $white;
  &__text {
    line-height: 42px;
    font-size: 24px;
    max-width: 832px;
    margin: 0 auto;
  }
  &__name {
    font-weight: bold;
    line-height: 28px;
    font-size: 16px;
    padding-top: 25px;
  }
}
// Jazz
.jazz-page {
  .quote {
    background: $blue;
  }
}

@media only screen and (max-width: $breakpoint_880) {
  .proud {
    .box-proud {
      padding: 40px 0;
    }
    .partners {
      img {
        margin-left: 13px;
        margin-right: 13px;
      }
    }
  }
  .quote {
    padding: 40px 30px;
    &__text {
      font-size: 20px;
      line-height: 34px;
      br {
        display: none;
      }
    }
  }
}

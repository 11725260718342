.giftcard {
  &__form-section {
    display: flex;
    justify-content: center;
    &.stars-bg {
      // background: url("../assets/images/giftcard2022/christmasdeco.png") top no-repeat; // fallback
      // background: url("../assets/images/giftcard2022/christmasdeco.png") top no-repeat, $grey_mlight;
      margin-bottom: 0;
      padding-top: 25px;
    }
    @media only screen and (max-width: $breakpoint_1024) {
      flex-direction: column;
      padding: 20px auto;
    }
    .gift-card-spacer {
      flex: 1;
    }

    .giftcard-box {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(5, 10, 34, 0.07);
      order: 2;
      display: flex;
      justify-content: center;
      padding-top: 80px;
      padding-bottom: 80px;
      flex: 4;
      margin-bottom: -120px;
      background: url("../assets/images/giftcard2022/christmasdeco.png") top no-repeat; // fallback
      background: url("../assets/images/giftcard2022/christmasdeco.png") top no-repeat, $grey_mlight;
      background-size: cover;
      @media only screen and (max-width: $breakpoint_1024) {
        background-size: cover;
        margin-bottom: unset;
      }

      @media only screen and (max-width: $breakpoint_1024) {
        width: 100%;
        margin: 20px auto;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &__image-container {
        height: 291px;
        position: relative;

        > img {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 300px;
          object-fit: cover;
          object-fit: cover;
          @media only screen and (max-width: $breakpoint_1024) {
            max-width: 100%;
          }
        }

        &__text1,
        &__text2 {
          position: absolute;
          left: 40px;
          padding-right: 40px;
          font-weight: 400;
          z-index: 2;
          background: radial-gradient(60.73% 60.73% at 52.54% 39.27%, #ebae71 0%, #ac7946 100%);
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
          cursor: default;
          user-select: none;

          &.dark-text {
            background: radial-gradient(60.73% 60.73% at 52.54% 39.27%, #141140 0%, #141140 100%);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
          }
        }

        &__text1 {
          top: 50px;
          font-family: "Playfair Display", serif;
          font-size: 40px;
          line-height: 53px;
          text-shadow: 5px 4px 2px rgba(67, 0, 0, 0.3);
        }

        &__text2 {
          top: 120px;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          line-height: 28px;

          @media only screen and (max-width: $breakpoint_1200) {
            top: 170px;
          }
        }
      }

      &__text-container {
        margin-right: auto;
        padding: 33px 38px;
        margin: auto 0px;
        font-family: "Roboto", sans-serif;
        position: relative;

        div:nth-of-type(1) {
          font-weight: bold;
          font-size: 20px;
          line-height: 26px;
          padding: 0px 0px 15px 0px;
        }

        & > div:nth-of-type(2),
        & > div:nth-of-type(3),
        & > div:nth-of-type(5) {
          i {
            font-style: italic;
          }
          font-size: 16px;
          line-height: 26px;
        }
        & > div:nth-of-type(4) {
          // separator line
          cursor: default;
          user-select: none;
          color: #dd4e52;
          padding: 10px 0px 15px 0px;
        }
      }
    }

    > input {
      &:focus {
        padding-top: unset;
      }
    }
  }

  &__entry-form {
    font-family: "Roboto", sans-serif;
    order: 2;
    flex: 5;

    @media only screen and (max-width: $breakpoint_1024) {
      margin: 0px auto;
      order: 1;
      width: unset;
      max-width: 600px;
    }

    > input {
      border: 1px solid #e0e0e0;
    }

    &.hidden {
      display: none;
    }

    &__description {
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
    }

    &__question1,
    &__question2,
    &__question3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
      padding-top: 30px;
      padding-bottom: 20px;

      &__options {
        display: flex;
        justify-content: flex-start;
        min-height: 37px;
        flex-wrap: wrap;

        @media only screen and (max-width: 560px) {
          flex-direction: column;
        }

        &__option {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-right: 50px;

          @media only screen and (max-width: 560px) {
            margin: 0px 0px 10px 0px;
          }

          svg {
            margin-right: 10px;
          }
        }
      }
    }

    &__question3 {
      padding-bottom: 10px; // overrides padding above

      &__explanation {
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        padding-bottom: 20px;
      }

      &__email {
        max-width: 279px;
      }
    }
  }

  &__entry-form--2 {
    font-family: "Roboto", sans-serif;
    order: 2;
    flex: 5;

    @media only screen and (max-width: $breakpoint_658) {
      width: 100%;
    }

    @media only screen and (max-width: $breakpoint_1024) {
      margin: 0px auto;
      order: 1;
    }

    &.hidden {
      display: none;
    }

    &__question1,
    &__question2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
      padding-bottom: 20px;

      &__options {
        display: flex;
        justify-content: flex-start;
        min-height: 37px;
        flex-wrap: wrap;

        input {
          &:focus {
            padding-top: 0px;
            padding-bottom: 0px;
          }
        }

        @media only screen and (max-width: 560px) {
          flex-direction: column;
        }

        &__option {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-right: 50px;

          svg {
            margin-right: 10px;
          }

          @media only screen and (max-width: 560px) {
            margin: 0px 0px 10px 0px;
          }
        }
      }
    }

    &__question2 {
      padding-top: 30px;

      &__rec {
        display: flex;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;

        @media only screen and (max-width: $breakpoint_518) {
          flex-direction: column;
        }

        &__name {
          margin: 0px 30px 15px 0px;

          @media only screen and (max-width: $breakpoint_518) {
            margin: 0px 0px 15px 0px;
          }
        }

        &__email {
          margin: 0px 0px 15px 0px;
        }
      }
    }

    &__question3 {
      &__your {
        display: flex;
        flex-direction: column;
        display: flex;

        &__name {
          width: 279px;
          margin: 0px 30px 0px 0px;

          @media only screen and (max-width: $breakpoint_518) {
            width: 100%;
          }
        }

        &__message {
          border: 1px solid #e0e0e0;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-weight: 400;
          resize: none;
          height: 86px;
          margin: 0px 0px 15px 0px;
          padding: 10px 10px 0px 10px;
          border: 1px solid #e0e0e0;
          outline: none;
          width: 447px;
          border-radius: $card_border_radius;
          &::placeholder {
            color: grey;
          }
          @media only screen and (max-width: $breakpoint_518) {
            width: 100%;
          }
        }
      }
    }

    &__customise_title {
      font-size: 20px;
      font-weight: 700;
      padding: 40px 0px 20px 0px;
    }
  }
  &__entry-form--4 {
    width: 100%;
    min-height: 100vh;
  }
  &__entry-form--3 {
    width: 600px;
    font-family: "Roboto", sans-serif;
    order: 2;
    flex: 5;

    .input-label {
      margin-right: 10px;
    }
    &__loading {
      display: flex;
      justify-content: center;
      padding-top: 200px;
    }

    @media only screen and (max-width: $breakpoint_658) {
      width: 100%;
    }

    @media only screen and (max-width: $breakpoint_1024) {
      margin: 0px auto;
      order: 1;
    }

    &.hidden {
      display: none;
    }
  }

  &__stripe-box {
    .row-form {
      margin-bottom: 16px;
      position: relative;
      .label {
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        margin-bottom: 8px;
      }

      > input {
        font-family: "Roboto", sans-serif;
        height: 48px;
        border: 1px solid $border;
        padding: 0 12px;
        display: flex;
        align-items: center;
        border-radius: $card_border_radius;
        width: 100%;
        &::placeholder {
          color: $border;
        }

        &.name-error {
          border-color: $red;
          margin-bottom: 0px;
        }
      }

      .cvc-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        .input-white {
          margin-right: 16px;
        }
        .card {
          margin-right: 8px;
          border: 1px solid $grey_light;
          border-radius: 4px;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          padding: 4px;
          font-size: 14px;
          color: $grey_light;
          width: 56px;
          height: 40px;
          position: relative;
          &.bar {
            &:before {
              content: "";
              position: absolute;
              left: 0px;
              width: 100%;
              height: 8px;
              background: $grey_light;
              top: 7px;
            }
          }
        }
      }

      .small-label {
        margin-top: 8px;
        font-size: 14px;
        line-height: 16px;
        color: $grey_light;
      }
    }

    .input-white {
      font-family: "Roboto", sans-serif;
      background: $white;
      border: 1px solid $border;
      padding: 15px 12px;
      width: 100%;
      position: relative;
      border-radius: $card_border_radius;

      &.error {
        border-color: $red;
      }
      &.cvc {
        max-width: 184px;
      }
    }

    .error-msg {
      font-size: 12px;
      line-height: 16px;
      color: $red;
      margin-top: 5px;
    }

    .green-tick {
      position: absolute;
      right: 10px;
      bottom: 17px;
    }

    .button-submit {
      margin-top: 24px;
      background: $red;
      height: 48px;
      width: 200px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold;
      color: $white;
      cursor: pointer;
      border: none;
      outline: none;
      > div {
        margin-top: -7px;
      }
      &.jazz {
        background: $blue;
      }
    }

    .box-error {
      background: rgba(221, 78, 82, 0.1);
      border: 1px solid $red;
      margin-top: 20px;
      padding: 16px 18px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 864px;
      .alert {
        margin-right: 18px;
      }
      .head-error {
        font-size: 16px;
        line-height: 23px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .sub-error {
        font-size: 14px;
        line-height: 20px;
        a {
          color: $red;
          text-decoration: none;
          margin-left: 5px;
        }
      }
    }
  }

  &__payment-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-height: 520px;
    min-height: 500px;
    a {
      text-decoration: none;
      color: inherit;
    }

    h1 {
      font-family: "Playfair Display", serif;
      font-size: 40px;
      font-weight: 400;
      margin: 0px auto;
      text-align: center;
      padding-bottom: 36px;
    }

    p {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      margin: 0px auto;
      text-align: center;
    }

    &__another-btn,
    &__home-btn {
      border-style: none;
      border-radius: 19.5px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      width: 190px;
      height: 39px;
      background: #dd4e52;
      margin: 20px auto 20px auto;
      cursor: pointer;
    }

    &__home-btn {
      color: #dd4e52;
      margin: 0px auto 0px auto;
      background: #f4f4f4;
    }
  }

  &__button-wrapper {
    width: 600px;
    @media only screen and (max-width: $breakpoint_518) {
      width: 100%;
    }
    @media only screen and (min-width: 1025px) {
      display: none;
    }
    @media only screen and (max-width: $breakpoint_1024) {
      margin: 20px auto;
      display: flex;
      order: 3;
    }
  }

  &__inputField {
    position: relative;
    > input {
      border: 1px solid #e0e0e0;
      height: 39px;
      margin: 0px 0px 15px 0px;
      padding: 10px;
      padding-right: 25px;
      width: 100%;
      border-radius: $card_border_radius;
      &:focus {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      &:disabled {
        background: white;
        color: grey;
      }

      &:disabled::placeholder {
        color: grey;
      }

      &::placeholder {
        color: grey;
      }
    }

    > img {
      position: absolute;
      top: 10%;
      right: 0;
    }

    &__required {
      color: #dd4e52;
      position: absolute;
      top: 5px;
    }
  }

  &__required {
    color: #dd4e52;
    vertical-align: super;
    font-size: 14px;
  }

  .benefits-list {
    list-style: disc;
    max-height: 500px;
    min-height: 250px;
    transition: max-height 200ms;
    &.features,
    &.benefits {
      overflow: hidden;
      // display: block;
    }
    &.plan-bullets {
      li {
        opacity: 1;
      }
    }
    &.show-less {
      min-height: unset;
      max-height: 0;
      overflow: hidden;
      visibility: hidden;

      &.plan-bullets {
        li {
          opacity: 0;
        }
      }
    }

    &.plan-bullets {
      li {
        transition: opacity 400ms;
        display: list-item;
      }
    }
  }
}

.giftcard {
  .steps {
    width: max-content;
    padding: 5px;
    border-radius: 20px;
    &__connector {
      margin: 0 5px;
      padding: 0 5px;
    }
  }
}

.steps-pill {
  .button-red-outlined {
    @media only screen and (max-width: $breakpoint_658) {
      font-size: 0;
      .step-back-arrow {
        margin-right: 0;
      }
    }
  }
  .button-red-outlined:hover {
    .step-back-arrow {
      filter: brightness(100);
    }
  }
  .step-back-arrow {
    height: 12px;
  }
}

// Common between Gift purchase and Voucher activation
.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  background-color: #f4f4f4;

  &__text {
    cursor: default;
  }

  &__radio {
    margin: 0px 10px;

    &.unchecked {
      cursor: pointer;
    }

    &.unavailable {
      cursor: default;
    }

    &svg {
      &circle {
        stroke: red;
        fill: red;
      }
    }
  }

  &__connector {
    color: #929292;
    font-size: 20px;
    cursor: default;
    user-select: none;

    @media only screen and (max-width: $breakpoint_518) {
      font-size: 10px;
    }
  }
  &__text {
    color: #929292;
  }

  &__icon {
    height: 20px;
    width: 20px;
    &.icon {
      height: 30px;
      width: 30px;
    }
  }
  &.yellow {
    color: #e17202;
    background-color: #fdf7f1;
    .steps__connector {
      color: #f3d2b0;
    }
    .steps__text {
      color: $brown;
    }
  }
  &.red {
    color: #edc2c3;
    background-color: #fff4f5;
    .steps__connector {
      color: #edc2c3;
    }
    .steps__text {
      color: $red_black;
    }
  }
  &.blue {
    color: $blue_light;
    background-color: #edf0fc;
    .steps__connector {
      color: $blue_light;
    }
    .steps__text {
      color: $black;
    }
  }
}

.react-date-picker {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation: fadeIn 0.5s ease both;
  animation-iteration-count: 1;
  z-index: 5;
  min-width: 150px;

  .react-date-picker__wrapper {
    border: 1px solid #e0e0e0;
    border-radius: $card_border_radius;
    padding: 0px 5px;
  }
  .react-date-picker__calendar {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation: fadeIn 0.5s ease both;
    animation-iteration-count: 1;
  }
}

.gift-next-button {
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.in-form {
    @media only screen and (max-width: $breakpoint_1024) {
      display: none;
    }
  }
}

.giftcard__hero {
  font-family: "Playfair Display", serif;
  color: white;
  background-color: #364375;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 40px;
  font-weight: 400;
  color: $black;
  margin: 25px 0 0;

  .hero_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex: 2;
    color: $white;
    @media only screen and (max-width: $breakpoint_768) {
      flex: 4;
    }
    h1 {
      line-height: 50px;
      margin-bottom: 20px;
    }
    p {
      font-family: $playfair;
      font-size: 20px;
      line-height: 25px;
    }
  }
  .hero_img {
    flex: 1;
    img {
      width: 100%;
    }
  }

  h1 {
    @media only screen and (max-width: $breakpoint_1024) {
      font-size: 40px;
      line-height: 52px;
    }

    @media only screen and (max-width: $breakpoint_518) {
      font-size: 27px;
      text-align: center;
    }
  }
}

.gift-loading-icon {
  display: flex;
  justify-content: center;
  height: 70vh;
  align-items: center;

  @media only screen and (max-width: $breakpoint_1024) {
    height: 40vh;
  }
}

.gift-plan-card {
  @extend .shadow;
  border: 1px solid $black;
  border-radius: $card_border_radius;
  // display: flex;
  flex-direction: column;
  max-width: 320px;
  position: relative;
  background-color: $white;
  height: max-content;
  margin-bottom: 10px;
  @media only screen and (max-width: $breakpoint_880) {
    .plan-prices-list {
      display: none !important;
    }
  }

  .gift-plan-label {
    background-color: $brown;
    color: $white;
    position: absolute;
    right: 0;
    top: 10px;
    border-top-left-radius: $card_border_radius;
    border-bottom-left-radius: $card_border_radius;
    padding: 3px;
  }
  .gift-plan-image-add-on {
    position: absolute;
    left: -90px;
    top: -77px;
    -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.4));
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.4));
  }
  .plan-title {
    min-height: 177px;
  }
  .plan-subtitle {
    min-height: 156px;
  }
  .section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-top-right-radius: $card_border_radius;
    border-top-left-radius: $card_border_radius;
    word-wrap: break-word;
    &.bordered {
      border-bottom: 1px solid $black;
    }
    .title {
      font-family: "Playfair Display", serif;
      font-size: 25px;
      font-weight: 600;
    }
    .sub-title {
      font-size: 16px;
      font-family: "Roboto", sans-serif;
    }

    &.bg-pink {
      background-color: #fcedee;
    }
    &.bg-yellow {
      background-color: #fdf7f1;
    }
    &.bg-blue {
      background-color: #edf0fc;
    }
  }
}

.giftcard-entry-step0 {
  @media only screen and (max-width: $breakpoint_880) {
    .gift-plan-card {
      &.bg-pink {
        order: 1;
      }
      &.bg-yellow {
        order: 0;
      }
      &.bg-blue {
        order: 2;
      }
    }
    &.flex-row {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.editable-input {
  input,
  input:focus,
  textarea,
  textarea:focus {
    height: unset;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0;
  }
  .edit-icon {
    height: $size_16;
    width: auto;
    color: $red;
    &.is-editing {
      color: $green;
    }
  }

  .value {
    font-weight: bold;
    color: $black;
  }
}

.total-section {
  .title {
    font-family: "Playfair Display", serif;
    font-size: 25px;
    font-weight: 600;
  }
}

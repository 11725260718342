.w-max-content {
  width: max-content;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.h-max-content {
  height: max-content;
}
.w-auto {
  width: auto;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

$spacing-sizes: 0 3 5 10 15 20 25 30 35 40 45 50;
.mx-md-auto {
  @media only screen and (max-width: $breakpoint_880) {
    margin-left: auto;
    margin-right: auto;
  }
}

@each $size in $spacing-sizes {
  .m-#{$size} {
    margin: #{$size}px;
  }
  .ml-#{$size} {
    margin-left: #{$size}px;
  }
  .mr-#{$size} {
    margin-right: #{$size}px;
  }
  .mt-#{$size} {
    margin-top: #{$size}px;
    &-sm {
      @media only screen and (max-width: $breakpoint_658) {
        margin-top: #{$size}px !important;
      }
    }
  }
  .mb-#{$size} {
    margin-bottom: #{$size}px;
  }
  .mx-#{$size} {
    margin-left: #{$size}px;
    margin-right: #{$size}px;
  }
  .my-#{$size} {
    margin-top: #{$size}px;
    margin-bottom: #{$size}px;
  }
  .p-#{$size} {
    padding: #{$size}px !important;
  }
  .pl-#{$size} {
    padding-left: #{$size}px;
  }
  .pr-#{$size} {
    padding-right: #{$size}px;
  }
  .pt-#{$size} {
    padding-top: #{$size}px;
  }
  .pb-#{$size} {
    padding-bottom: #{$size}px;
  }
  .px-#{$size} {
    padding-left: #{$size}px;
    padding-right: #{$size}px;
  }
  .py-#{$size} {
    padding-top: #{$size}px;
    padding-bottom: #{$size}px;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

.text-grey {
  color: $grey_dark;
}

.text-underline {
  text-decoration: underline;
  &.red {
    text-decoration-color: $red;
  }
  &.blue {
    text-decoration-color: $blue;
  }
}

.text-black {
  color: $black;
}

.bg-transparent {
  background-color: transparent;
}

.hide {
  display: none !important;
  &-880 {
    @media only screen and (max-width: $breakpoint_880) {
      display: none !important;
    }
  }
  &-1200 {
    @media only screen and (max-width: $breakpoint_1200) {
      display: none !important;
    }
  }
  &-md {
    @media only screen and (max-width: $breakpoint_768) {
      display: none !important;
    }
  }
  &-sm {
    @media only screen and (max-width: $breakpoint_658) {
      display: none !important;
    }
  }
}

.shadow {
  box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
}

.bordered {
  border: 1px solid $greyLight;
  &.rounded {
    border-radius: $card_border_radius;
  }
}

.overflow {
  &-hidden {
    overflow: hidden;
  }
  &-y-hidden {
    overflow-y: hidden;
  }
  &-x-hidden {
    overflow-x: hidden;
  }
}

.justify-center {
  justify-content: center;
  &-md {
    @media only screen and (max-width: $breakpoint_880) {
      justify-content: center;
    }
  }
  &-xs {
    @media only screen and (max-width: $breakpoint_518) {
      justify-content: center;
    }
  }
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-flex-start {
  justify-content: flex-start;
}

.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-start {
  align-items: flex-start;
}
.align-self-center {
  align-self: center;
}
.flex {
  display: flex;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.flex-wrap {
  flex-wrap: wrap;
  &-mobile {
    @media only screen and (max-width: $breakpoint_880) {
      flex-wrap: wrap;
    }
  }
  &-tablet {
    @media only screen and (max-width: $breakpoint_658) {
      flex-wrap: wrap;
    }
  }
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-space-between {
  justify-content: space-between;
}

.italic {
  font-style: italic;
}

.playfair {
  font-family: $playfair;
}
.roboto {
  font-family: $roboto;
}

$font-sizes: 12 14 16 20 24 30 32 40;
@each $size in $font-sizes {
  .font-#{$size} {
    font-size: #{$size}px;
  }
}

.font-red {
  color: $red;
}

.font-blue {
  color: $blue;
}

$border-thickness: 0 2 4 6 8 10;
@each $width in $border-thickness {
  .border-#{$width} {
    border-width: #{$width}px;
  }
}

.text-white {
  color: white;
}

.text-classical {
  color: #dd4e52;
}

.bold {
  font-weight: bold;
}

.text-jazz {
  color: #4866e0;
}

.cursor-pointer {
  cursor: pointer;
}

.leading-3 {
  line-height: 0.75rem;
}
.leading-4 {
  line-height: 1rem;
}
.leading-5 {
  line-height: 1.25rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.leading-7 {
  line-height: 1.75rem;
}
.leading-8 {
  line-height: 2rem;
}
.leading-9 {
  line-height: 2.25rem;
}
.leading-10 {
  line-height: 2.5rem;
}
.leading-none {
  line-height: 1;
}
.leading-tight {
  line-height: 1.25;
}
.leading-snug {
  line-height: 1.375;
}
.leading-normal {
  line-height: 1.5;
}
.leading-relaxed {
  line-height: 1.625;
}
.leading-loose {
  line-height: 2;
}
.lowercase {
  text-transform: lowercase;
}
